// https://www.w3schools.com/css/tryit.asp?filename=trycss_grid

import { TEXT_COLOR } from "../colors_drone";

import { useEffect, useState } from "react";

export const BoxLabels = ({ val_armable, val_armed,  }: { val_armable: string; val_armed: string;  }) => {
    return (
        <div style={grid_container_style}>
            <div style={grid_item_style}>
                <div style={top_label_style}> ARMABLE </div>
                <div style={bottom_label_style}> {val_armable} </div>
            </div>
            <div style={grid_item_style}>
                <div style={top_label_style}> ARMED </div>
                <div style={bottom_label_style}> {val_armed} </div>
            </div>
            <div style={grid_item_style_plus}>
                <div style={{ marginTop: 8 }}>+</div>
            </div>
        </div>
    );
};

const grid_container_style = {
    display: "grid",
    gridTemplateColumns: "100px 100px",
    // backgroundColor: "#2196F3",
    padding: "0px",
};

const grid_item_style = {
    // backgroundColor: "rgba(255, 255, 255, 0.8)",
    color: `${TEXT_COLOR}`,
    border: "0.5px solid",
    borderColor: `${TEXT_COLOR}`,
    margin: 2,
    paddingBottom: 0,
    height: "60px",
    textAlign: "center" as const,
};

const grid_item_style_plus = {
    color: `${TEXT_COLOR}`,
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    border: "0.5px solid",
    borderColor: `${TEXT_COLOR}`,
    margin: 2,
    paddingTop: 0,
    height: 60,
    textAlign: "center" as const,
    // verticalAlign: "middle",
    fontSize: 30,
    fontWeight: "lighter",
    alignItems: "center",
};

const top_label_style = {
    fontSize: 10,
    paddingTop: 8,
};

const bottom_label_style = {
    fontSize: 14,
    fontWeight: "bold",
};
