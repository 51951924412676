import styled from "@emotion/styled";

const StyledBox =  styled.div`
  border-radius:50%;
  background: white;
  -webkit-mask: radial-gradient(transparent 3px,#000 2px);
  mask: radial-gradient(transparent 3px,#000 2px);
    &::before {
      content:"";
      display:block;
      padding-top:100%;
    }
`

const StyledContainer = styled.div`
  max-width:10px;
  animation:change 3s linear alternate infinite;
`

export const SmallRing = () => {
    return <div>
        <StyledContainer>
            <StyledBox></StyledBox>
        </StyledContainer>
    </div>
}