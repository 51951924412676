import { Container, Row, Col } from 'reactstrap';
import {Header} from "./Header";
import styled from "@emotion/styled";
import {LeftBar} from "./LeftBar";
import {CenterBar} from "./CenterBar";
import {RightBar} from "./RightBar";

const OuterDiv = styled.div`
  padding: 5%;
`

export const Overlay = ({producerChannel, setBrightness, manager, payloads, lockState, setLockState, ringCenterRef, setStart, roll, pitch, yaw, robotIp, pilotIp, latestMessage, instruction, heartbeat, left, forward, battery, distanceTravelled, gpsGoal}: any) => {
    return <OuterDiv>
        <Header robotIp={robotIp} pilotIp={pilotIp} latestMessage={latestMessage} />
        <Row>
            <Col xs={"5"}>
                <LeftBar setStart={setStart} roll={roll} pitch={pitch} yaw={yaw} instruction={instruction} gpsGoal={gpsGoal} heartbeat={heartbeat} left={left} forward={forward} battery={battery} distanceTravelled={distanceTravelled} />
            </Col>

            <Col xs={"2"}>
                <CenterBar lockState={lockState} setLockState={setLockState} ringCenterRef={ringCenterRef} />
            </Col>

            <Col xs={"5"}>
                <RightBar setBrightness={setBrightness} producerChannel={producerChannel} manager={manager} payloads={payloads} />
            </Col>
        </Row>
    </OuterDiv>
}