
export const Arrows = ({keysPressed}: any) => {
    return <svg width="90" height="90" viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity={keysPressed?.includes('w') ? "1" : "0.4"} d="M126.781 65C118.562 57.1833 107.858 52.8906 96.5001 52.8906C85.142 52.8906 74.4384 57.1833 66.2196 65L29.0001 27.5954C47.1633 9.79098 71.079 0 96.5001 0C121.921 0 145.843 9.79098 164 27.5954L126.781 65Z" fill="white"/>
        <path opacity={keysPressed.includes('s') ? "1" : "0.4"} d="M96.5001 193C71.079 193 47.1633 183.209 29.0001 165.405L66.2196 128C74.4323 135.817 85.142 140.109 96.5001 140.109C107.858 140.109 118.568 135.817 126.781 128L164 165.405C145.843 183.209 121.921 193 96.5001 193Z" fill="white"/>
        <path opacity={keysPressed.includes('a') ? "1" : "0.4"} d="M27.5954 164C9.79105 145.843 0.00012207 121.921 0.00012207 96.5C0.00012207 71.0788 9.79105 47.1632 27.5954 29L65.0001 66.2198C48.8809 83.1652 48.8809 109.835 65.0001 126.78L27.5954 164Z" fill="white"/>
        <path opacity={keysPressed.includes('d') ? "1" : "0.4"} d="M128 126.78C144.119 109.835 144.119 83.1652 128 66.2198L165.405 29C183.209 47.1632 193 71.0788 193 96.5C193 121.921 183.209 145.843 165.405 164L128 126.78Z" fill={"white"} />
        <path opacity="0.8" d="M96.5001 26L90.0001 32H103L96.5001 26Z" fill={keysPressed?.includes('w') ? "#C0C9B6" : "#151816"}/>
        <path opacity="0.8" d="M96.5001 167L90.0001 161H103L96.5001 167Z" fill={keysPressed.includes('s') ? "#C0C9B6" : "#151816"}/>
        <path opacity="0.8" d="M26.0001 96.5L32.0001 103V90L26.0001 96.5Z" fill={keysPressed.includes('a') ? "#C0C9B6" : "#151816"}/>
        <path opacity="0.8" d="M171 97.5L165 104V91L171 97.5Z" fill={keysPressed.includes('d') ? "#C0C9B6" : "#151816"}/>
    </svg>
}