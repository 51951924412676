import { useEffect, useState } from "react";

export const ButtonRect = ({ text, background_color, text_color, width }: { text: string; background_color: string; text_color: string; width: string }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            style={{
                cursor: "pointer",
                color: text_color,
                // backgroundColor: !isHovering ? background_color : "rgba(255, 255, 255, 0.55)",
                backgroundColor: isMouseDown ?  ("rgba(255, 255, 255, 0.85)") : (!isHovering ? background_color : "rgba(255, 255, 255, 0.55)"),
                verticalAlign: "middle",
                width: width,
                height: "23px",
                lineHeight: "23px",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                borderRadius: 0,
                fontSize: 12,
                opacity: "90%",
            }}
        >
            {text}
        </div>
    );

    {
        /* <span style="background-color:#ddd;">and some text
with a different background</span> */
    }
};

const div_style = {
    // textAlign: "center",
    alignItems: "center",
};

const button_style = {
    color: "green",
    fontSize: "12px",
    // fontWeight: "normal",
    fontWeight: "bold",
    margin: "0",
    border: "1px solid blue",
    width: 200,
    height: 20,
    // textAlign: "center",
    alignItems: "center",
    // position: "absolute",
    // fontFamily: 'Rajdhani'
};
