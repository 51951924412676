// Gamepad: https://www.youtube.com/watch?v=UPaKoTfqk8k

import styled from "@emotion/styled";
import AppFpv from "./AppFpv";
import { useEffect, useRef, useState } from "react";
import { Signaling } from "../Signaling";
import { sfuUrl, tokenUrl } from "../config";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { MediasoupManager } from "../MediasoupManager";
import { Channel } from "../types/messages";
import { Video } from "./Video";

const InnerContent = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
`;

export const FpvUI = ({ setStart, produce_user_id, produce_room_id, produce_robot_id, produce_stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_video_stream_id, consume_data_stream_id }: any) => {
    const [fpvMode, setFpvMode] = useState("[ Mode ]");
    const [fpvIsArmable, setFpvIsArmable] = useState("False");
    const [fpvIsArmed, setFpvIsArmed] = useState("False");
    const [fpvYaw, setFpvYaw] = useState("no");
    const [fpvLastYawGoal, setFpvLastYawGoal] = useState<number>(0); // remember what the last set goal was either by button or key
    const [fpvAltitude, setFpvAltitude] = useState("8.43");
    const [fpvPosLocalNorth, setFpvPosLocalNorth] = useState("2.23");
    const [fpvPosLocalEast, setFpvPosLocalEast] = useState("2.34");
    const [fpvPosLocalDown, setFpvPosLocalDown] = useState("4.56");
    const [fpvVelX, setFpvVelX] = useState("0.22");
    const [fpvVelY, setFpvVelY] = useState("0.43");
    const [fpvVelZ, setFpvVelZ] = useState("1.23");
    const [fpvAttitudeRoll, setFpvAttitudeRoll] = useState("no");
    const [fpvAttitudePitch, setFpvAttitudePitch] = useState("no");
    const [fpvAttitudeYaw, setFpvAttitudeYaw] = useState("no");
    const [fpvSystemStatus, setFpvSystemStatus] = useState("SYSTEMSTATUS:ACTIVE");
    const [fpvSensitivity, setFpvSensitivity] = useState<number>(0.3);
    const fpvSensitivityStateRef = useRef<number>(0.3); // https://stackoverflow.com/questions/71447566/react-state-variables-not-updating-in-function
    fpvSensitivityStateRef.current = fpvSensitivity;
    const [fpvGamepadYaw, setFpvGamepadYaw] = useState<number>(0.0);
    const [fpvGamepadThrottle, setFpvGamepadThrottle] = useState<number>(0.0);
    const [fpvGamepadRoll, setFpvGamepadRoll] = useState<number>(0.0);
    const [fpvGamepadPitch, setFpvGamepadPitch] = useState<number>(0.0);
    const [fpvBatteryVoltage, setFpvBatteryVoltage] = useState("24.34");
    const [fpvBatteryCurrent, setFpvBatteryCurrent] = useState("25.5");
    const [fpvBatteryLevel, setFpvBatteryLevel] = useState("87");
    const [fpvGps, setFpvGps] = useState("GPSInfo:fix=3,num_sat=se12");
    const [heartbeatFpv, setHeartbeatFpv] = useState("HEARTBEAT -");
    const heartbeatFromDrone = useRef(0.0);
    const [robotIp, setRobotIp] = useState("");
    const [pilotIp, setPilotIp] = useState("127.0.0.1");
    const [instruction, setInstruction] = useState("");
    const [localLeftRight, setLocalLeftRight] = useState<number>(0);
    const [localFwdBwd, setLocalFwdBwd] = useState<number>(0);
    const [localSteer, setLocalSteer] = useState<number>(0);
    const [upDown, setUpDown] = useState<number>(0);
    const [lockState, setLockState] = useState(false);
    const [payloads, setPayloads] = useState([]);
    const [latestMessage, setLatestMessage] = useState("-");
    const [brightness, setBrightness] = useState(40);

    const [signaling, setSignaling]: any = useState<Signaling>();
    const [token, setToken] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [mediasoupManager, setMediasoupManager] = useState<MediasoupManager>();
    const [producerChannel, setProducerChannel] = useState({});
    const [consumers, setConsumers] = useState<{ type: "data" | "media"; channel: Channel; track?: MediaStreamTrack; textData?: string; pointCloudData?: string }[]>([]);

    const connect = (token: string, user_id: string, room_id: string, robot_id: string, stream_id: string, consume_user_id: string, consume_room_id: string, consume_robot_id: string, consume_data_stream_id: string, consume_video_stream_id: string) => {
        if (token) {
            const sig = new Signaling(`${sfuUrl}?token=${token}`);
            const outgoingChannel = {
                user_id: user_id,
                room_id: room_id,
                robot_id: robot_id,
                stream_id: stream_id,
            };
            sig.onWsConnect = async () => {
                // setHeartbeatFpv("HEARTBEAT OK");
                const manager = new MediasoupManager(sig);
                await manager.createSendTransport();
                await manager.createRecvTransport();

                manager.createDatachannel({
                    user_id,
                    room_id,
                    robot_id,
                    stream_id,
                });

                manager.consumeVideo({
                    user_id: consume_user_id,
                    room_id: consume_room_id,
                    robot_id: consume_robot_id,
                    stream_id: consume_video_stream_id,
                });

                manager.consumeData({
                    user_id: consume_user_id,
                    room_id: consume_room_id,
                    robot_id: consume_robot_id,
                    stream_id: consume_data_stream_id,
                });

                setProducerChannel({
                    user_id,
                    room_id,
                    robot_id,
                    stream_id,
                });

                window.setTimeout(async () => {
                    setPilotIp(await manager.getLocalIp());
                    setRobotIp(await manager.getRemoteIp());
                }, 5000);

                manager.onTrack = (track: any, channel: Channel) => {
                    setConsumers((v) => [...v.filter((x) => x.type !== "media"), { track, channel, type: "media", textData: "" }]);
                };

                manager.onDataConsumerCreated = (channel: Channel) => {
                    setConsumers((v) => [...v, { channel, type: "data", textData: "" }]);
                };

                manager.onData = (textData: string, channel: Channel) => {
                    try {
                        const updates = JSON.parse(textData);
                        updates.forEach(({ type, value }: any) => {
                            if (type === "fpv_mode") {
                                // console.log("fpvmode", value)
                                setFpvMode(value);
                            }
                            if (type === "heartbeat_from_drone") {
                                heartbeatFromDrone.current = value
                            }
                            if (type === "fpv_altitude") {
                                // console.log("fpvmode", value)
                                setFpvAltitude(value);
                            }
                            if (type === "fpv_yaw") {
                                setFpvYaw(value);
                            }
                            if (type === "fpv_is_armable") {
                                setFpvIsArmable(value);
                            }
                            if (type === "fpv_is_armed") {
                                setFpvIsArmed(value);
                            }
                            if (type === "fpv_gps") {
                                setFpvGps(value);
                            }
                            if (type === "fpv_vel_x") {
                                setFpvVelX(value);
                            }
                            if (type === "fpv_vel_y") {
                                setFpvVelY(value);
                            }
                            if (type === "fpv_vel_z") {
                                setFpvVelZ(value);
                            }
                            if (type === "fpv_attitude_roll") {
                                setFpvAttitudeRoll(value);
                            }
                            if (type === "fpv_attitude_pitch") {
                                setFpvAttitudePitch(value);
                            }
                            if (type === "fpv_attitude_yaw") {
                                setFpvAttitudeYaw(value);
                            }
                            if (type === "fpv_pos_local_north") {
                                setFpvPosLocalNorth(value);
                            }

                            if (type === "fpv_pos_local_east") {
                                setFpvPosLocalEast(value);
                            }

                            if (type === "fpv_pos_local_down") {
                                setFpvPosLocalDown(value);
                            }

                            if (type === "fpv_battery_voltage") {
                                setFpvBatteryVoltage(value);
                            }
                            if (type === "fpv_battery_current") {
                                setFpvBatteryCurrent(value);
                            }
                            if (type === "fpv_battery_level") {
                                setFpvBatteryLevel(value);
                                console.log("fpv_battery_level");
                            }
                            if (type === "instruction") {
                                setInstruction(value);
                            }
                            if (type === "latestMessage") {
                                setLatestMessage(value);
                            }
                            if (type === "payloads") {
                                setPayloads(value);
                            }
                            if (type === "position") {
                                if (value.x) {
                                }
                                if (value.y) {
                                }
                            }
                        });
                    } catch (e) {
                        console.log("Error while parsing data" + e);
                    }
                };

                setMediasoupManager(manager);

                document.addEventListener("keydown", (e) => {
                    if (e.key == "w" && !e.repeat) {
                        //w down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: -fpvSensitivityStateRef.current }), outgoingChannel);
                        console.log("w Down");
                    }

                    if (e.key == "s" && !e.repeat) {
                        //s down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: fpvSensitivityStateRef.current }), outgoingChannel);
                        console.log("s Down");
                    }

                    if (e.key == "a" && !e.repeat) {
                        //a down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: -fpvSensitivityStateRef.current, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("a Down");
                    }

                    if (e.key == "d" && !e.repeat) {
                        //d down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: fpvSensitivityStateRef.current, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("d Down");
                    }

                    if (e.key == "q" && !e.repeat) {
                        //q down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: -fpvSensitivityStateRef.current, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("q Down");
                    }

                    if (e.key == "e" && !e.repeat) {
                        //e down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: fpvSensitivityStateRef.current, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("e Down");
                    }

                    if (e.key == "ArrowUp" && !e.repeat) {
                        //ArrowUp  down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: -fpvSensitivityStateRef.current, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("ArrowUp Down");
                    }

                    if (e.key == "ArrowDown" && !e.repeat) {
                        //ArrowDown down
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: fpvSensitivityStateRef.current, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("ArrowDown Down");
                    }

                    if (e.key == "/" && !e.repeat) {
                        // / down
                        manager.sendData(JSON.stringify({ type: "camera_reset", value: 1 }), outgoingChannel);
                        console.log("/ Down");
                    }

                    if (e.key == "b" && !e.repeat) {
                        // b down
                        manager.sendData(JSON.stringify({ type: "beep", value: 1 }), outgoingChannel);
                        console.log("b Down");
                    }

                    if (e.key == "." && !e.repeat) {
                        // . down
                        console.log(". Down");
                        setFpvSensitivity((x: number) => Math.round(Math.min(x + 0.1, 1.0) * 10) / 10);
                    }

                    if (e.key == "," && !e.repeat) {
                        // , down
                        console.log(", Down");
                        // setFpvSensitivity((x: number) => Math.max(x - 0.1, 0.1));
                        setFpvSensitivity((x: number) => Math.round(Math.max(x - 0.1, 0.1) * 10) / 10);
                    }

                    // if (e.keyCode === 38) {
                    //     //up-arrow (repeating)
                    //     setLocalCameraUpDown((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_up_down", value: t + 0.1}), outgoingChannel);
                    //         // console.log(t + 0.1)
                    //         return parseFloat((t + 0.1).toFixed(1))
                    //     })
                    //     console.log('up-arrow Down')
                    //     console.log(localCameraUpDown)
                    // }

                    // if (e.keyCode === 40) {
                    //     //down-arrow (repeating)
                    //     setLocalCameraUpDown((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_up_down", value: t - 0.1}), outgoingChannel);
                    //         // console.log(t - 0.1)
                    //         return parseFloat((t - 0.1).toFixed(1))
                    //     })
                    //     console.log('down-arrow Down')
                    //     console.log(localCameraUpDown)
                    // }

                    // if (e.keyCode === 37) {
                    //     //up-arrow (repeating)
                    //     setLocalCameraLeftRight((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_left_right", value: t - 0.1}), outgoingChannel);
                    //         // console.log(t - 0.1)
                    //         return parseFloat((t - 0.1).toFixed(1))
                    //     })
                    //     console.log('left-arrow Down')
                    //     console.log(localCameraLeftRight)
                    // }

                    // if (e.keyCode === 39) {
                    //     //right-arrow (repeating)
                    //     setLocalCameraLeftRight((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_left_right", value: t + 0.1}), outgoingChannel);
                    //         console.log(t + 0.1)
                    //         return parseFloat((t + 0.1).toFixed(1))
                    //     })
                    //     console.log('right-arrow Down')
                    //     console.log(localCameraLeftRight)
                    // }
                });

                document.addEventListener("keyup", (e) => {
                    if (e.key == "w") {
                        // w up
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("w UP");
                    }

                    if (e.key == "s") {
                        // s up
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("s UP");
                    }

                    if (e.key == "a") {
                        // a up
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("a UP");
                    }

                    if (e.key == "d") {
                        // d up
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("d UP");
                    }

                    if (e.key == "q") {
                        //q up
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("q Up");
                    }

                    if (e.key == "e") {
                        //e up
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                        console.log("e Up");
                    }

                    if (e.key == "b") {
                        //b up
                        manager.sendData(JSON.stringify({ type: "beep", value: 0 }), outgoingChannel);
                        console.log("b Up");
                    }

                    if (e.key == "]") {
                        setBrightness((x: number) => Math.min(x + 10, 200));
                    }

                    if (e.key == "[") {
                        setBrightness((x: number) => Math.max(x - 10, 0));
                    }

                    if (e.key == "ArrowDown") {
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                    }

                    if (e.key == "ArrowUp") {
                        manager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: 0.0, stick_throttle: 0.0, stick_roll: 0.0, stick_pitch: 0.0 }), outgoingChannel);
                    }



                });
            };
            setSignaling(sig);
            sig.setUsers = (e: any) => {};
            sig.onWsStateChange = () => {
                // setHeartbeatFpv("false");
            };
        }
    };

    const getToken = async (user_id: string, room_id: string, robot_id: string, stream_id: string, consume_user_id: string, consume_room_id: string, consume_robot_id: string, consume_data_stream_id: string, consume_video_stream_id: string) => {
        const response = await axios.post(`${tokenUrl}/api/v1/token`, {
            props: [
                {
                    kind: "producer",
                    channel: {
                        user_id,
                        room_id,
                        robot_id,
                        stream_id,
                    },
                    type: "stream",
                },
                {
                    kind: "consumer",
                    channel: {
                        user_id: consume_user_id,
                        room_id: consume_room_id,
                        robot_id: consume_robot_id,
                    },
                    type: "robot",
                },
            ],
        });
        connect(response.data.token, user_id, room_id, robot_id, stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_data_stream_id, consume_video_stream_id);
        setToken(response.data.token);
    };

    useEffect(() => {
        const user_id = produce_user_id;
        const room_id = produce_room_id;
        const robot_id = produce_robot_id;
        const stream_id = produce_stream_id;
        getToken(user_id, room_id, robot_id, stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_data_stream_id, consume_video_stream_id);
    }, [produce_user_id, produce_room_id, produce_robot_id, produce_stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_video_stream_id, consume_data_stream_id]);

    // Gamepad Poll loop
    useEffect(() => {
        // console.log("EFFECT **************");

        const outgoingChannel = {
            user_id: produce_user_id,
            room_id: produce_room_id,
            robot_id: produce_robot_id,
            stream_id: produce_stream_id,
        };

        if (mediasoupManager !== undefined) {
            const interval = setInterval(() => {
                const _controller = navigator.getGamepads()[0];

                if (_controller) {
                    console.log("loop gamepad");

                    const yaw = Math.round(fpvSensitivityStateRef.current * _controller.axes[0] * 100) / 100;
                    const throttle = Math.round(fpvSensitivityStateRef.current * _controller.axes[1] * 100) / 100;
                    const roll = Math.round(fpvSensitivityStateRef.current * _controller.axes[2] * 100) / 100;
                    const pitch = Math.round(fpvSensitivityStateRef.current * _controller.axes[3] * 100) / 100;
                    // console.log('fpvSensitivity = ', fpvSensitivity);
                    // console.log('fpvSensitivityStateRef.current = ', fpvSensitivityStateRef.current);

                    setFpvGamepadYaw(yaw);
                    setFpvGamepadThrottle(throttle);
                    setFpvGamepadRoll(roll);
                    setFpvGamepadPitch(pitch);

                    // console.log("outgoingChannel = ");
                    // console.log(outgoingChannel);
                    // console.log("mediasoupManager = ");
                    // console.log(mediasoupManager);
                    mediasoupManager.sendData(JSON.stringify({ type: "set_sticks", stick_yaw: yaw, stick_throttle: throttle, stick_roll: roll, stick_pitch: pitch }), outgoingChannel);
                }
            }, 100);
        }
    }, [mediasoupManager]);

    // Heatbeat loop
    useEffect(() => {
        console.log("EFFECT HEARTBEAT **************");

        const outgoingChannel = {
            user_id: produce_user_id,
            room_id: produce_room_id,
            robot_id: produce_robot_id,
            stream_id: produce_stream_id,
        };

        if (mediasoupManager !== undefined) {
            const interval = setInterval(() => {
                const now = Date.now() / 1000

                // Check last heartbeat from drone
                if (now-heartbeatFromDrone.current > 0.8 ) { 
                    setHeartbeatFpv("HEARTBEAT -")
                } else {
                    setHeartbeatFpv("HEARTBEAT OK")
                }

                // send heartbeat to Drone
                mediasoupManager.sendData(JSON.stringify({ type: "heartbeat_from_server", value: now }), outgoingChannel);

            }, 500);
        }
    }, [mediasoupManager]);



    useEffect(() => {
        const interval = setInterval(async () => {
            const stats = await  mediasoupManager.getStats()
            console.log("stats = ", stats);

            // console.log("This will be called every 2 seconds");
        }, 2000);

        return () => clearInterval(interval);
    }, [mediasoupManager]);


    




    return (
        <div style={{ background: "black", height: "100vh" }}>
            <Video brightness={brightness} track={consumers.find((c) => c.type === "media")?.track} />
            <InnerContent>
                <AppFpv
                    fpvMode={fpvMode}
                    fpvIsArmable={fpvIsArmable}
                    fpvIsArmed={fpvIsArmed}
                    fpvYaw={fpvYaw}
                    fpvAltitude={fpvAltitude}
                    fpvPosLocalNorth={fpvPosLocalNorth}
                    fpvPosLocalEast={fpvPosLocalEast}
                    fpvPosLocalDown={fpvPosLocalDown}
                    fpvVelX={fpvVelX}
                    fpvVelY={fpvVelY}
                    fpvVelZ={fpvVelZ}
                    fpvAttitudeRoll={fpvAttitudeRoll}
                    fpvAttitudePitch={fpvAttitudePitch}
                    fpvAttitudeYaw={fpvAttitudeYaw}
                    fpvSystemStatus={fpvSystemStatus}
                    fpvSensitivity={fpvSensitivity}
                    fpvGamepadYaw={fpvGamepadYaw}
                    fpvGamepadThrottle={fpvGamepadThrottle}
                    fpvGamepadRoll={fpvGamepadRoll}
                    fpvGamepadPitch={fpvGamepadPitch}
                    fpvBatteryVoltage={fpvBatteryVoltage}
                    fpvBatteryCurrent={fpvBatteryCurrent}
                    fpvBatteryLevel={fpvBatteryLevel}
                    fpvGps={fpvGps}
                    heartbeatFpv={heartbeatFpv}
                    robotIp={robotIp}
                    pilotIp={pilotIp}
                    instruction={instruction}
                    localLeftRight={localLeftRight}
                    localFwdBwd={localFwdBwd}
                    localSteer={localSteer}
                    upDown={upDown}
                    lockState={lockState}
                    payloads={payloads}
                    latestMessage={latestMessage}
                    brightness={brightness}
                    setBrightness={setBrightness}
                    producerChannel={producerChannel}
                    manager={mediasoupManager}
                />
            </InnerContent>
        </div>
    );
};
