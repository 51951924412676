import {Header} from "./Header";
import {MiddleSection} from "./MiddleSection";
import { BottomBar } from "./BottomBar"
import {UpperMiddleSection} from "./UpperMiddleSection";

export const Overlay = ({
    irisPosNorth, irisPosEast, irisPosDown, irisAltitudePitch , irisAltitudeYaw, irisAltitudeRoll, irisVelX, irisVelY, irisVelZ,
    irisGps, producerChannel, irisMode, setBrightness, manager, payloads, lockState, setLockState, ringCenterRef, setStart, roll, pitch, yaw, robotIp, pilotIp, latestMessage, instruction, heartbeat, left, forward, battery, distanceTravelled, gpsGoal
}: any) => {
    return <div>
        <Header />
        <UpperMiddleSection />
        <MiddleSection irisGps={irisGps} irisMode={irisMode} roll={roll} pitch={pitch} yaw={yaw} battery={battery} heartbeat={heartbeat} gpsGoal={gpsGoal} />
        <BottomBar
            yaw={yaw}
            irisPosNorth={irisPosNorth} irisPosEast={irisPosEast} irisPosDown={irisPosDown}
            irisAltitudePitch={irisAltitudePitch} irisAltitudeYaw={irisAltitudeYaw} irisAltitudeRoll={irisAltitudeRoll}
            irisVelX={irisVelX} irisVelY={irisVelY} irisVelZ={irisVelZ}
            producerChannel={producerChannel} manager={manager} />
    </div>
}