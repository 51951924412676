import {Button, Input} from "reactstrap";
import {useCallback, useEffect, useState} from "react";
import {MediasoupManager} from "../MediasoupManager";
import {Signaling} from "../Signaling";
import {Video} from "./Video";
import {ThreePointCloudController} from "./ThreePointCloudController";
import {User} from "./SFUConnection";
import {Channel} from "../types/messages";
import { channelEquals } from "../utils";

interface Props {
    signaling: Signaling;
    users: any;
}

export const Subscribe = ({ signaling, users }: Props) => {
    const [mediasoupManager, setMediasoupManager]: any = useState();
    const [consumers, setConsumers] = useState<{type: "data" | "media", channel: Channel, track?: MediaStreamTrack, textData?: string, pointCloudData?: string}[]>([]);
    const [remoteData, setRemoteData] : any = useState("");
    const [remotePointcloudData, setRemotePointcloudData] : any = useState("");
    const [subscribeType, setSubscribeType] = useState<"data" | "media">("data");
    const [channel, setChannel] = useState({
        user_id: "",
        robot_id: "",
        room_id: "",
        stream_id: ""
    });

    useEffect(() => {
        const manager = new MediasoupManager(signaling);
        manager.createRecvTransport();
        setMediasoupManager(manager)
        manager.onTrack = (track: any, channel: Channel) => {
            setConsumers((v) => [...v, {track, channel, type: "media", textData: ""}])
            console.error(track);
        }
        manager.onDataConsumerCreated = (channel: Channel) => {
            setConsumers((v) => [...v, {channel, type: "data", textData: ""}])
        }
        manager.onData = (textData: string, channel: Channel) => {
            const { type, data } = JSON.parse(textData);
            if (type === "pointcloud") {
                setConsumers((consumers) => {
                    return consumers.map(v => {
                        if (channelEquals((v.channel), channel)) {
                            return {
                                ...v,
                                pointCloudData: data,
                                textData: ""
                            }
                        }
                        return v;
                    })
                });
            } else {
                setConsumers((consumers) => {
                    return consumers.map(v => {
                        if (channelEquals((v.channel), channel)) {
                            return {
                                ...v,
                                textData: data,
                                pointCloudData: ""
                            }
                        }
                        return v;
                    })
                });
            }
        }
    }, [signaling, setConsumers]);

    const subscribe = useCallback((channel: Channel, type: "data" | "media") => {
        if (type === "media") {
            mediasoupManager.consumeVideo(channel);
        } else {
            mediasoupManager.consumeData(channel);
        }
    }, [mediasoupManager])

    const getAllStreams = () => {
        if (!users) {
            return []
        }
        const response: {user_id: string; room_id: string; robot_id: string; stream_id: string; type: "media" | "data"}[] = [];
        users.map(({channel, type}: User) => {
            response.push({
                user_id: channel.user_id,
                room_id: channel.room_id,
                stream_id: channel.stream_id,
                robot_id: channel.robot_id,
                type
            });
        })
        return response;
    }

    return <div>
        <Input value={channel.user_id} onChange={(e) => setChannel((c) => ({...c, user_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="User id" />
        <Input value={channel.room_id} onChange={(e) => setChannel((c) => ({...c, room_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="Room id" />
        <Input value={channel.robot_id} onChange={(e) => setChannel((c) => ({...c, robot_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="Robot id" />
        <Input value={channel.stream_id} onChange={(e) => setChannel((c) => ({...c, stream_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="Stream id" />
        <Input value={subscribeType} onChange={(e) => setSubscribeType(e.target.value as "media" | "data")} type="select" name="select" id="input" placeholder="Type" >
            <option>data</option>
            <option>media</option>
        </Input>

        <Button color={"success"} onClick={() => subscribe(channel, subscribeType)}>Subscribe</Button>

        {getAllStreams().map(data => {
            let consumer = consumers.filter(({channel, type}) => {
                if (channelEquals(channel, {
                    user_id: data.user_id, room_id: data.room_id, robot_id: data.robot_id, stream_id: data.stream_id
                }) && type === data.type) {
                    return true;
                }
                return false;
            })[0];

            return <div style={{border: "1px solid black", borderRadius: 2, padding: 10}}>
            User ID: {data.user_id}<br/>
            Room ID: {data.room_id}<br/>
            Robot ID: {data.robot_id}<br/>
            Stream ID: {data.stream_id}<br/>
            Type: {data.type}<br/>
            {!consumer && <Button color={"success"} onClick={() => subscribe({
                user_id: data.user_id, room_id: data.room_id, robot_id: data.robot_id, stream_id: data.stream_id
            }, data.type)}>Subscribe</Button>}
            {consumer && consumer.textData && <>{consumer.textData}</>}
            {consumer && consumer.pointCloudData && <ThreePointCloudController data={consumer.pointCloudData} />}
            {consumer && data.type === "media" && <Video track={consumer.track} />}
        </div>})}
    </div>
}