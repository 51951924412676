import {Arrows} from "./Arrows";
import {BLACK1, GRAY2, YELLOW1} from "../new/colors";
import {Button} from "../new/Button";
import {NumericalScale} from "../new/NumericalScale";
import {useEffect, useState} from "react";
import {Arrows2} from "./Arrows2";
import {InputBox} from "./InputBox";

export const BottomBar = ({
      irisPosNorth, irisPosEast, irisPosDown, irisAltitudePitch , irisAltitudeYaw, irisAltitudeRoll, irisVelX, irisVelY, irisVelZ,
      manager, producerChannel, yaw
}: any) => {
    const [keysPressed, setKeysPressed] = useState<string[]>([]);
    const keyDownHandler = (e: any) => {
        setKeysPressed((x) => [...x, e.key]);
    }

    const keyUpHandler = (e: any) => {
        setKeysPressed((x) => x.filter(y => y !== e.key));
    }

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler)
        document.addEventListener("keyup", keyUpHandler)
        return () => {
            document.removeEventListener("keydown", keyDownHandler)
            document.removeEventListener("keyup", keyUpHandler)
        }
    });

    return <div style={{position: "fixed", bottom: 20, minWidth: "100%", display: "flex", justifyContent: "space-between"}}>
        <div style={{display: "flex"}}>
            <div style={{display: "flex"}}>
                <div style={{marginLeft: 10}}>
                    <Arrows keysPressed={keysPressed} />
                </div>
                <div style={{marginLeft: 10}}>
                    <Arrows2 keysPressed={keysPressed} />
                </div>
            </div>
            <div style={{marginLeft: '1%', display: "flex", marginTop: 100}}>
                <div style={{width: 250, padding: 25, height: 170, background: "#262d25", position: "absolute", bottom: 100, marginLeft: 20}}>
                    <InputBox title={"TAKEOFF"} onClick={(value: string) => {
                        manager.sendData(JSON.stringify({type: "arm_and_takeoff", takeoff_altitude: value}), producerChannel);
                    }} />
                    <InputBox title={"RISE"} onClick={(value: string) => {
                        manager.sendData(JSON.stringify({type: "rise", rise_altitude: value}), producerChannel);
                    }} />
                    <InputBox title={"YAW"} onClick={(value: string) => {
                        manager.sendData(JSON.stringify({type: "yaw", yaw_goal: value}), producerChannel);
                    }} />
                </div>
                <div onClick={() => {
                    manager.sendData(JSON.stringify({type: "land"}), producerChannel);
                }} style={{marginLeft: '2vw'}}>
                    <Button color={BLACK1} background={GRAY2} text={"LAND"}  />
                </div>
                <div onClick={() => {
                    manager.sendData(JSON.stringify({type: "rtl"}), producerChannel);
                }} style={{marginLeft: '2vw'}}>
                    <Button color={BLACK1} background={GRAY2} text={"RTL"}  />
                </div>
                <div onClick={() => {
                    manager.sendData(JSON.stringify({type: "stop_all_skills"}), producerChannel);
                }} style={{marginLeft: '2vw'}}>
                    <Button color={BLACK1} background={GRAY2} text={"STOP"}  />
                </div>
            </div>
        </div>
        <div>
        <div style={{display: "flex", marginTop: 100}}>
            <div style={{  width: 20, height: 45, background: YELLOW1,paddingTop: 20, paddingLeft: 6, color: BLACK1, fontSize: 15, marginRight: 20}}>
                <b>
                    &gt;
                </b>
            </div>
            <NumericalScale value={`${irisAltitudeRoll} ${irisAltitudePitch} ${irisAltitudeYaw}`} heading={"ALTITUDE"} showEndSaparator={true} />
            <NumericalScale value={`${yaw}`} heading={"YAW"} showEndSaparator={true} />
            <NumericalScale value={`${irisPosNorth} ${irisPosEast} ${irisPosDown}`} heading={"POSITION"} showEndSaparator={true} />
            <NumericalScale value={`${irisVelX} ${irisVelY} ${irisVelZ}`} heading={"VELOCITY"} showEndSaparator={false} />
        </div>
        </div>
    </div>
}