import React, {useCallback, useEffect, useRef} from "react";

export const ThreePointCloudController = ({data}) => {
    const containerRef = useRef(null);

    const setupThree = useCallback(() => {
        let materials = [];

        const HEIGHT = window.innerHeight / 2;
        const WIDTH = window.innerWidth / 2;
        const windowHalfX = WIDTH / 2;
        const windowHalfY = HEIGHT / 2;

        const fieldOfView = 75;
        const aspectRatio = WIDTH / HEIGHT;
        const nearPlane = 1;
        const farPlane = 3000;

        const cameraZ = farPlane / 3; /*	So, 1000? Yes! move on!	*/
        const fogHex = 0x000000; /* As black as your heart.	*/
        const fogDensity = 0.0007; /* So not terribly dense?	*/

        const camera = new window.THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
        camera.position.z = cameraZ;

        const scene = new window.THREE.Scene();
        scene.fog = new window.THREE.FogExp2(fogHex, fogDensity);

        const container = containerRef.current;

        const geometry = new window.THREE.Geometry();

        const particleCount = data.length;


        for (let i = 0; i < particleCount; i++) {

            var vertex = new window.THREE.Vector3();
            vertex.x = data[i][0] - 1000;
            vertex.y = data[i][1] - 1000;
            vertex.z = data[i][2] - 1000;

            geometry.vertices.push(vertex);
        }

        const parameters = [
            [
                [1, 1, 0.5], 5
            ]
        ];
        const parameterCount = parameters.length;

        for (let i = 0; i < parameterCount; i++) {

            const color = parameters[i][0];
            const size = parameters[i][1];

            materials[i] = new (window.THREE).PointCloudMaterial({
                size: size
            });

            const particles = new (window.THREE).PointCloud(geometry, materials[i]);

            particles.rotation.x = Math.random() * 6;
            particles.rotation.y = Math.random() * 6;
            particles.rotation.z = Math.random() * 6;

            scene.add(particles);
        }

        /*	If my calculations are correct, when this baby hits 88 miles per hour...
    you're gonna see some serious shit.	*/

        const renderer = new window.THREE.WebGLRenderer(); /*	Rendererererers particles.	*/
        renderer.setPixelRatio(window.devicePixelRatio); /*	Probably 1; unless you're fancy.	*/
        renderer.setSize(WIDTH, HEIGHT); /*	Full screen baby Wooooo!	*/
        container.innerHTML = "";
        container.appendChild(renderer.domElement); /* Let's add all this crazy junk to the page.	*/

        function animate() {
            requestAnimationFrame(animate);
            render();
        }

        requestAnimationFrame(animate);
        render();

        function render() {
            var time = Date.now() * 0.00005;

            camera.lookAt(scene.position);

            for (let i = 0; i < scene.children.length; i++) {

                var object = scene.children[i];

                if (object instanceof window.THREE.PointCloud) {

                    object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));
                }
            }

            for (let i = 0; i < materials.length; i++) {

                const color= parameters[i][0];

                const h = (360 * ((color)[0] + time) % 360) / 360;
                (materials)[i].color.setHSL(h, color[1], color[2]);
            }

            renderer.render(scene, camera);
        }
    }, [containerRef, data]);


    useEffect(() => {
        if (containerRef && containerRef.current) {
            setupThree()
        }
    }, [containerRef, setupThree, data])

    return <div ref={containerRef}>

    </div>
}