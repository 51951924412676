// https://codepen.io/sosuke/pen/Pjoqqp

import { useEffect, useState } from "react";

export const ButtonSvg = ({ file, size, invert_color_by, opacity_default, opacity_hover, opacity_click }: { file: string; size: string; invert_color_by: string; opacity_default: string; opacity_hover: string; opacity_click: string; }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    return (
        <img
            src={file}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            
            style={{
                height: size,
                cursor: "pointer",
                // filter: "invert(100%) opacity(75%)",
                // filter: !isHovering ? "invert(100%) opacity(75%)" : "invert(100%) opacity(100%)",
                filter: isMouseDown ?  (`invert(${invert_color_by}) opacity(${opacity_click})`) : (!isHovering ? `invert(${invert_color_by}) opacity(${opacity_default})` : `invert(${invert_color_by}) opacity(${opacity_hover})`)
            }}
        />

    );

    {
        /* <span style="background-color:#ddd;">and some text
with a different background</span> */
    }
};

const div_style = {
    // textAlign: "center",
    alignItems: "center",
};

const button_style = {
    color: "green",
    fontSize: "12px",
    // fontWeight: "normal",
    fontWeight: "bold",
    margin: "0",
    border: "1px solid blue",
    width: 200,
    height: 20,
    // textAlign: "center",
    alignItems: "center",
    // position: "absolute",
    // fontFamily: 'Rajdhani'
};
