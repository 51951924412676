import { ButtonRect } from "./ButtonRect";
import { InputboxRect } from "./InputboxRect";
import { TEXT_COLOR, BUTTON_BG_COLOR, INPUT_BG_COLOR } from "../colors_drone";

import { useEffect, useState } from "react";

export const CommandTable = ({ dummy, manager, producerChannel }: any) => {
    const [valueTakeoffHeight, setValueTakeoffHeight] = useState("1.0");
    const [valueRise, setValueRise] = useState("2.0");
    const [valueYaw, setValueYaw] = useState("0");
    const [valueVelX, setValueVelX] = useState("0");
    const [valueVelY, setValueVelY] = useState("0");
    const [valueVelZ, setValueVelZ] = useState("0");

    return (
        <div style={{ margin: "50" }}>
            {/* ---------------  */}
            {/* ARM AND TAKEOFF  */}
            {/* ---------------  */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
                {/* Input Box ARM */}
                <InputboxRect
                    onChange={(value: string) => {
                        console.log("outer: val changed " + value);
                        setValueTakeoffHeight(value);
                    }}
                    background_color={INPUT_BG_COLOR}
                    text_color={TEXT_COLOR}
                    default_value={valueTakeoffHeight}
                    width="50px"
                />
                {/* Button ARM */}
                <div
                    onClick={() => {
                        console.log("Button ARM Clicked " + valueTakeoffHeight);
                        console.log("valueTakeoffHeight = " , valueTakeoffHeight);
                        manager.sendData(JSON.stringify({type: "arm_and_takeoff", takeoff_altitude: valueTakeoffHeight}), producerChannel);
                    }}
                >
                    <ButtonRect background_color={BUTTON_BG_COLOR} text_color={TEXT_COLOR} text="ARM AND TAKEOFF" width="150px" />
                </div>
            </div>
            {/* ---------------  */}
            {/* RISE  */}
            {/* ---------------  */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
                {/* Input Box RISE */}
                <InputboxRect
                    onChange={(value: string) => {
                        console.log("outer: val changed " + value);
                        setValueRise(value);
                    }}
                    background_color={INPUT_BG_COLOR}
                    text_color={TEXT_COLOR}
                    default_value={valueRise}
                    width="50px"
                />
                {/* Button RISE */}
                <div
                    onClick={() => {
                        console.log("Button RISE Clicked " + valueRise);
                        manager.sendData(JSON.stringify({type: "rise", rise_altitude: valueRise}), producerChannel);
                    }}
                >
                    <ButtonRect background_color={BUTTON_BG_COLOR} text_color={TEXT_COLOR} text="RISE" width="150px" />
                </div>
            </div>
            {/* ---------------  */}
            {/* HEADING  */}
            {/* ---------------  */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
                {/* Input Box HEADING */}
                <InputboxRect
                    onChange={(value: string) => {
                        console.log("outer: val changed " + value);
                        setValueYaw(value);
                    }}
                    background_color={INPUT_BG_COLOR}
                    text_color={TEXT_COLOR}
                    default_value={valueYaw}
                    width="50px"
                />
                {/* Button HEADING */}
                <div
                    onClick={() => {
                        console.log("Button HEADING Clicked " + valueYaw);
                        manager.sendData(JSON.stringify({type: "yaw", yaw_goal: valueYaw}), producerChannel);
                    }}
                >
                    <ButtonRect background_color={BUTTON_BG_COLOR} text_color={TEXT_COLOR} text="HEADING" width="150px" />
                </div>
            </div>
            {/* ---------------  */}
            {/* VELOCITY  */}
            {/* ---------------  */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
                {/* Input Box VELOCITY X */}
                <InputboxRect
                    onChange={(value: string) => {
                        console.log("outer: val changed " + value);
                        setValueVelX(value);
                    }}
                    background_color={INPUT_BG_COLOR}
                    text_color={TEXT_COLOR}
                    default_value={valueVelX}
                    width="50px"
                />
                {/* Input Box VELOCITY Y */}
                <InputboxRect
                    onChange={(value: string) => {
                        console.log("outer: val changed " + value);
                        setValueVelY(value);
                    }}
                    background_color={INPUT_BG_COLOR}
                    text_color={TEXT_COLOR}
                    default_value={valueVelY}
                    width="50px"
                />
                {/* Input Box VELOCITY Z */}
                <InputboxRect
                    onChange={(value: string) => {
                        console.log("outer: val changed " + value);
                        setValueVelZ(value);
                    }}
                    background_color={INPUT_BG_COLOR}
                    text_color={TEXT_COLOR}
                    default_value={valueVelZ}
                    width="50px"
                />
                {/* Button VELOCITY */}
                <div
                    onClick={() => {
                        console.log("Button VELOCITY Clicked " + valueVelX + " " + valueVelY + " " + valueVelZ);
                        manager.sendData(JSON.stringify({type: "set_velocity", velocity_goal_x: valueVelX, velocity_goal_y: valueVelY, velocity_goal_z: valueVelZ}), producerChannel);
                    }}
                >
                    <ButtonRect background_color={BUTTON_BG_COLOR} text_color={TEXT_COLOR} text="VEL" width="50px" />
                </div>
            </div>
            {/* ---------------  */}
            {/* RTL  */}
            {/* ---------------  */}
            <div style={{ marginBottom: 3 }}>
                <div
                    onClick={() => {
                        console.log("Button RTL Clicked");
                        manager.sendData(JSON.stringify({type: "rtl"}), producerChannel);
                    }}
                >
                    <ButtonRect background_color={BUTTON_BG_COLOR} text_color={TEXT_COLOR} text="RTL" width="200px" />
                </div>
            </div>
            {/* ---------------  */}
            {/* LAND  */}
            {/* ---------------  */}
            <div style={{ marginBottom: 3 }}>
                <div
                    onClick={() => {
                        console.log("Button LAND Clicked");
                        manager.sendData(JSON.stringify({type: "land"}), producerChannel);
                    }}
                >
                    <ButtonRect background_color={BUTTON_BG_COLOR} text_color={TEXT_COLOR} text="LAND" width="200px" />
                </div>
            </div>
            {/* ---------------  */}
            {/* STOP  */}
            {/* ---------------  */}
            <div style={{ marginBottom: 3 }}>
                <div
                    onClick={() => {
                        console.log("Button STOP Clicked");
                        manager.sendData(JSON.stringify({type: "stop_all_skills"}), producerChannel);
                    }}
                >
                    <ButtonRect background_color={BUTTON_BG_COLOR} text_color={TEXT_COLOR} text="STOP" width="200px" />
                </div>
            </div>
        </div>
    );
};
