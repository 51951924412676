import { useEffect, useState } from "react";
import { Signaling } from "../Signaling";
import { sfuUrl } from "../config";
import { MediasoupManager } from "../MediasoupManager";
import { Channel } from "../types/messages";
import { useSearchParams } from "react-router-dom";
const DELAY_BETWEEN_MESSAGES = 0.1; // in seconds
const MAX_MESSAGES = 1000;

export const PiCam = () => {
    const [avgValue, setAvgValue] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        console.log('searchParams.get("resolution") = ', searchParams.get("resolution"));
        console.log('searchParams.get("device") = ', searchParams.get("device"));

        const token =
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9ucyI6W3siY2hhbm5lbCI6eyJ1c2VyX2lkIjoiMiIsInJvb21faWQiOiIyIiwicm9ib3RfaWQiOiIyIiwic3RyZWFtX2lkIjoiMSJ9LCJraW5kIjoicHJvZHVjZXIiLCJ0eXBlIjoiYWxsIn0seyJjaGFubmVsIjp7InVzZXJfaWQiOiIyIiwicm9vbV9pZCI6IjIiLCJyb2JvdF9pZCI6IjIiLCJzdHJlYW1faWQiOiIxIn0sImtpbmQiOiJjb25zdW1lciIsInR5cGUiOiJhbGwifV0sImlhdCI6MTY1NTkyMzQ0NH0.bj-pPhCrHs0QT2qcLW8JIPTiK1u46vOvNkzfg4rR57E";
        const sig = new Signaling(`${sfuUrl}?token=${token}`);
        const outgoingChannel = {
            user_id: "2",
            room_id: "2",
            robot_id: "2",
            stream_id: "1",
        };
        sig.setUsers = () => {};
        sig.onWsConnect = async () => {
            const manager = new MediasoupManager(sig);
            await manager.createSendTransport();
            await manager.createRecvTransport();
            manager.sendVideo(outgoingChannel, searchParams.get("resolution"), searchParams.get("aspectRatio"), searchParams.get("device"));
        };
    }, []);
    return <div>Producing video at resolution {searchParams.get("resolution") || 480}p</div>;
};
