import styled from "@emotion/styled";
import {Overlay} from "./Overlay";
import {useEffect, useRef, useState} from "react";
import {Signaling} from "../Signaling";
import {sfuUrl, tokenUrl} from "../config";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {MediasoupManager} from "../MediasoupManager";
import {Channel} from "../types/messages";
import {Video} from "./Video";

const InnerContent = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
`

export const DroneUI = ({setStart, produce_user_id, produce_room_id, produce_robot_id, produce_stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_video_stream_id, consume_data_stream_id}: any) => {
    const [robotIp, setRobotIp] = useState("");
    const [pilotIp, setPilotIp] = useState("");
    const [instruction, setInstruction] = useState();
    const [heartbeat, setHeartbeat] = useState(false);
    const [sourceLocation, setSourceLocation] = useState();
    const [destinationLocation, setDestinationLocation] = useState();
    const [distanceTravelled, setDistanceTravelled] = useState(0);
    const [battery, setBattery] = useState(0);
    const [forward, setForward] = useState<number>(0);
    const [left, setLeft] = useState<number>(0);
    const [localFwdBwd, setLocalFwdBwd] = useState<number>(0);
    const [localLeftRight, setLocalLeftRight] = useState<number>(0);
    const [localSteer, setLocalSteer] = useState<number>(0);
    const [upDown, setUpDown] = useState<number>(0)
    const [localCameraUpDown, setLocalCameraUpDown] = useState<number>(0);
    const [localCameraLeftRight, setLocalCameraLeftRight] = useState<number>(0);
    const [irisMode, setIrisMode] = useState("");

    const [robotId, setRobotId] = useState();
    const [pilotId, setPilotId] = useState();
    const [lockState, setLockState] = useState(false);
    const [payloads, setPayloads] = useState([])
    const [latestMessage, setLatestMessage] = useState("-");
    const [roll, setRoll] = useState(0);
    const [pitch, setPitch] = useState(0);
    const [yaw, setYaw] = useState(0);
    const [brightness, setBrightness] = useState(40);
    const [irisGps, setIrisGps] = useState('');
    const [gpsGoal, setGpsGoal] = useState("");
    const [irisVelX, setIrisVelX] = useState(0);
    const [irisVelY, setIrisVelY] = useState(0);
    const [irisVelZ, setIrisVelZ] = useState(0);

    const [irisPosNorth, setIrisPosNorth] = useState(0);
    const [irisPosEast, setIrisPosEast] = useState(0);
    const [irisPosDown, setIrisPosDown] = useState(0);


    const [irisAltitudePitch, setIrisAltitudePitch] = useState(0);
    const [irisAltitudeYaw, setIrisAltitudeYaw] = useState(0);
    const [irisAltitudeRoll, setIrisAltitudeRoll] = useState(0);

    const [signaling, setSignaling]: any = useState<Signaling>();
    const [token, setToken] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [mediasoupManager, setMediasoupManager] = useState<MediasoupManager>();
    const [producerChannel, setProducerChannel] = useState({});
    const [consumers, setConsumers] = useState<{type: "data" | "media", channel: Channel, track?: MediaStreamTrack, textData?: string, pointCloudData?: string}[]>([]);

    const connect = (token: string, user_id: string, room_id: string, robot_id: string, stream_id: string, consume_user_id: string, consume_room_id: string, consume_robot_id: string, consume_data_stream_id: string, consume_video_stream_id: string) => {
        if (token) {
            const sig = new Signaling(`${sfuUrl}?token=${token}`)
            const outgoingChannel = {
                user_id: user_id,
                room_id: room_id,
                robot_id: robot_id,
                stream_id: stream_id
            };
            sig.onWsConnect = async() => {
                setHeartbeat(true);
                const manager = new MediasoupManager(sig);
                await manager.createSendTransport();
                await manager.createRecvTransport();

                manager.createDatachannel({
                    user_id,
                    room_id,
                    robot_id,
                    stream_id
                });

                manager.consumeVideo({
                    user_id: consume_user_id,
                    room_id: consume_room_id,
                    robot_id: consume_robot_id,
                    stream_id: consume_video_stream_id
                });

                manager.consumeData({

                    user_id: consume_user_id,
                    room_id: consume_room_id,
                    robot_id: consume_robot_id,
                    stream_id: consume_data_stream_id
                });

                setProducerChannel({
                    user_id,
                    room_id,
                    robot_id,
                    stream_id
                });

                window.setTimeout(async() => {
                    setPilotIp(await manager.getLocalIp());
                    setRobotIp(await manager.getRemoteIp());
                }, 5000)

                manager.onTrack = (track: any, channel: Channel) => {
                    setConsumers((v) => [...v.filter(x => x.type !== "media"), {track, channel, type: "media", textData: ""}])
                }

                manager.onDataConsumerCreated = (channel: Channel) => {
                    setConsumers((v) => [...v, {channel, type: "data", textData: ""}])
                }

                manager.onData = (textData: string, channel: Channel) => {
                    try {
                        const updates = JSON.parse(textData);
                        updates.forEach(({type, value}: any) => {
                            if (type === "distanceTravelled") {
                                setDistanceTravelled(value);
                            }
                            if (type === "iris_mode") {
                                setIrisMode(value);
                            }
                            if (type === "iris_gps") {
                                setIrisGps(value)
                            }
                            if (type === "iris_vel_x") {
                                setIrisVelX(value)
                            }
                            if (type === "iris_vel_y") {
                                setIrisVelY(value)
                            }
                            if (type === "iris_vel_z") {
                                setIrisVelZ(value)
                            }
                            if (type === "iris_attitude_roll") {
                                setIrisAltitudeRoll(value)
                            }
                            if (type === "iris_attitude_pitch") {
                                setIrisAltitudePitch(value)
                            }
                            if (type === "iris_attitude_yaw") {
                                setIrisAltitudeYaw(value)
                            }
                            if (type === "iris_pos_local_north") {
                                setIrisPosNorth(value);
                            }

                            if (type === "iris_pos_local_east") {
                                setIrisPosEast(value);
                            }

                            if (type === "iris_pos_local_down") {
                                setIrisPosDown(value);
                            }

                            if (type === "iris_battery_current") {
                                setBattery(value.toFixed(1));
                            }
                            if (type === "gpsGoal") {
                                setGpsGoal(value)
                            }
                            if (type === "iris_yaw") {
                                setYaw(value.toFixed(2));
                            }
                            if (type === "instruction") {
                                setInstruction(value)
                            }
                            if (type === "latestMessage") {
                                setLatestMessage(value)
                            }
                            if (type === "payloads") {
                                setPayloads(value)
                            }
                            if (type === "position") {
                                if (value.x) {
                                }
                                if (value.y) {
                                }
                            }
                        })
                    } catch(e) {
                        console.log("Error while parsing data" + e);
                    }
                }


                setMediasoupManager(manager)
                // document.addEventListener('keypress', (e) => {
                //     if (e.keyCode === 38) {
                //         // up-arrow
                //         setLocalCameraUpDown((t) => {
                //             manager.sendData(JSON.stringify({type: "camera_up_down", value: t + 0.1}), outgoingChannel);
                //             return parseFloat((t + 0.1).toFixed(1))
                //         })
                //     }
                //     if (e.key == '40') {
                //         //a
                //         setLocalCameraUpDown(t => {
                //             manager.sendData(JSON.stringify({type: "camera_up_down", value: t - 0.1}), outgoingChannel);
                //             return parseFloat((t - 0.1).toFixed(1))
                //         })
                //     }

                // if (e.key == "s") {
                //     //s
                //     setLocalForward(t => {
                //         manager.sendData(JSON.stringify({type: "top", value: t - 0.1}), outgoingChannel);
                //         return parseFloat((t - 0.1).toFixed(1))
                //     })
                //     manager.sendData(JSON.stringify({type: "down"}), outgoingChannel);
                // }

                // if (e.key == "d") {
                //     //d
                //     setLocalLeft(t => {
                //         manager.sendData(JSON.stringify({type: "left", value: t + 0.1}), outgoingChannel);
                //         return parseFloat((t + 0.1).toFixed(1))
                //     })
                // }
                // });

                document.addEventListener('keydown', (e) => {
                    if (e.key == "w" && !e.repeat) {
                        //w down
                        setLocalFwdBwd((t) => {
                            manager.sendData(JSON.stringify({type: "fwd_bwd", value: 0.7}), outgoingChannel);
                            return parseFloat((0.7).toFixed(1))
                        })
                        console.log('w Down')
                    }

                    if (e.key == "s" && !e.repeat) {
                        //s down
                        setLocalFwdBwd((t) => {
                            manager.sendData(JSON.stringify({type: "fwd_bwd", value: -0.7}), outgoingChannel);
                            return parseFloat((-0.7).toFixed(1))
                        })
                        console.log('s Down')
                    }

                    if (e.key == "a" && !e.repeat) {
                        //a down
                        setLocalLeftRight((t) => {
                            manager.sendData(JSON.stringify({type: "left_right", value: -0.7}), outgoingChannel);
                            return parseFloat((-0.7).toFixed(1))
                        })
                        console.log('a Down')
                    }

                    if (e.key == "d" && !e.repeat) {
                        //d down
                        setLocalLeftRight((t) => {
                            manager.sendData(JSON.stringify({type: "left_right", value: 0.7}), outgoingChannel);
                            return parseFloat((0.7).toFixed(1))
                        })
                        console.log('d Down')
                    }

                    if (e.key == "q" && !e.repeat) {
                        //q down
                        setLocalSteer((t) => {
                            manager.sendData(JSON.stringify({type: "steer", value: -0.7}), outgoingChannel);
                            return parseFloat((-0.7).toFixed(1))
                        })
                        console.log('q Down')
                    }

                    if (e.key == "e" && !e.repeat) {
                        //e down
                        setLocalSteer((t) => {
                            manager.sendData(JSON.stringify({type: "steer", value: 0.7}), outgoingChannel);
                            return parseFloat((0.7).toFixed(1))
                        })
                        console.log('e Down')
                    }

                    if (e.key == "ArrowUp" && !e.repeat) {
                        //e down
                        setUpDown((t) => {
                            manager.sendData(JSON.stringify({type: "up_down", value: 0.7}), outgoingChannel);
                            return parseFloat((0.7).toFixed(1))
                        })
                        console.log('up arrow')
                    }

                    if (e.key == "ArrowDown" && !e.repeat) {
                        //e down
                        setUpDown((t) => {
                            manager.sendData(JSON.stringify({type: "up_down", value: -0.7}), outgoingChannel);
                            return parseFloat((0.7).toFixed(1))
                        })
                        console.log('down arrow')
                    }

                    if (e.key == "e" && !e.repeat) {
                        //e down
                        setLocalSteer((t) => {
                            manager.sendData(JSON.stringify({type: "steer", value: 0.7}), outgoingChannel);
                            return parseFloat((0.7).toFixed(1))
                        })
                        console.log('e Down')
                    }

                    if (e.key == "/" && !e.repeat) {
                        // / down
                        manager.sendData(JSON.stringify({type: "camera_reset", value: 1}), outgoingChannel);
                        console.log('/ Down')
                    }

                    if (e.key == "b" && !e.repeat) {
                        // b down
                        manager.sendData(JSON.stringify({type: "beep", value: 1}), outgoingChannel);
                        console.log('b Down')
                    }

                    if (e.keyCode === 38) {
                        //up-arrow (repeating)
                        setLocalCameraUpDown((t) => {
                            manager.sendData(JSON.stringify({type: "camera_up_down", value: t + 0.1}), outgoingChannel);
                            // console.log(t + 0.1)
                            return parseFloat((t + 0.1).toFixed(1))
                        })
                        console.log('up-arrow Down')
                        console.log(localCameraUpDown)
                    }

                    if (e.keyCode === 40) {
                        //down-arrow (repeating)
                        setLocalCameraUpDown((t) => {
                            manager.sendData(JSON.stringify({type: "camera_up_down", value: t - 0.1}), outgoingChannel);
                            // console.log(t - 0.1)
                            return parseFloat((t - 0.1).toFixed(1))
                        })
                        console.log('down-arrow Down')
                        console.log(localCameraUpDown)
                    }

                    if (e.keyCode === 37) {
                        //up-arrow (repeating)
                        setLocalCameraLeftRight((t) => {
                            manager.sendData(JSON.stringify({type: "camera_left_right", value: t - 0.1}), outgoingChannel);
                            // console.log(t - 0.1)
                            return parseFloat((t - 0.1).toFixed(1))
                        })
                        console.log('left-arrow Down')
                        console.log(localCameraLeftRight)
                    }

                    if (e.keyCode === 39) {
                        //right-arrow (repeating)
                        setLocalCameraLeftRight((t) => {
                            manager.sendData(JSON.stringify({type: "camera_left_right", value: t + 0.1}), outgoingChannel);
                            console.log(t + 0.1)
                            return parseFloat((t + 0.1).toFixed(1))
                        })
                        console.log('right-arrow Down')
                        console.log(localCameraLeftRight)
                    }

                });

                document.addEventListener('keyup', (e) => {
                    if (e.key == "w") {
                        // w up
                        setLocalFwdBwd((t) => {
                            manager.sendData(JSON.stringify({type: "fwd_bwd", value: 0.0}), outgoingChannel);
                            return parseFloat((0.0).toFixed(1))
                        })
                        console.log('w UP')
                    }

                    if (e.key == "s") {
                        // s up
                        setLocalFwdBwd((t) => {
                            manager.sendData(JSON.stringify({type: "fwd_bwd", value: 0.0}), outgoingChannel);
                            return parseFloat((0.0).toFixed(1))
                        })
                        console.log('s UP')
                    }

                    if (e.key == "a") {
                        // a up
                        setLocalLeftRight((t) => {
                            manager.sendData(JSON.stringify({type: "left_right", value: 0.0}), outgoingChannel);
                            return parseFloat((0.0).toFixed(1))
                        })
                        console.log('a UP')
                    }

                    if (e.key == "d") {
                        // d up
                        setLocalLeftRight((t) => {
                            manager.sendData(JSON.stringify({type: "left_right", value: 0.0}), outgoingChannel);
                            return parseFloat((0.0).toFixed(1))
                        })
                        console.log('d UP')
                    }

                    if (e.key == "q") {
                        //q up
                        setLocalSteer((t) => {
                            manager.sendData(JSON.stringify({type: "steer", value: 0.0}), outgoingChannel);
                            return parseFloat((0.0).toFixed(1))
                        })
                        console.log('q Up')
                    }

                    if (e.key == "e") {
                        //e up
                        setLocalSteer((t) => {
                            manager.sendData(JSON.stringify({type: "steer", value: 0.0}), outgoingChannel);
                            return parseFloat((0.0).toFixed(1))
                        })
                        console.log('e Up')
                    }

                    if (e.key == "b") {
                        //b up
                        manager.sendData(JSON.stringify({type: "beep", value: 0}), outgoingChannel);
                        console.log('b Up')
                    }

                });



            }
            setSignaling(sig)
            sig.setUsers = (e: any) => {}
            sig.onWsStateChange = () => {
                setHeartbeat(false);
            }
        }
    };

    const getToken = async(user_id: string, room_id: string, robot_id: string, stream_id: string, consume_user_id: string, consume_room_id: string, consume_robot_id: string, consume_data_stream_id: string, consume_video_stream_id: string) => {
        const response = await axios.post(`${tokenUrl}/api/v1/token`, {
            props: [{
                kind: "producer",
                channel: {
                    user_id,
                    room_id,
                    robot_id,
                    stream_id
                },
                type: "stream"
            }, {
                kind: "consumer",
                channel: {
                    user_id: consume_user_id,
                    room_id: consume_room_id,
                    robot_id: consume_robot_id,
                },
                type: "robot"
            },
            ]
        })
        connect(response.data.token, user_id, room_id, robot_id, stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_data_stream_id, consume_video_stream_id);
        setToken(response.data.token);
    };

    useEffect(() => {
        const user_id = produce_user_id;
        const room_id = produce_room_id;
        const robot_id = produce_robot_id;
        const stream_id = produce_stream_id;
        getToken(user_id, room_id, robot_id, stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_data_stream_id, consume_video_stream_id);
    }, [produce_user_id, produce_room_id, produce_robot_id, produce_stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_video_stream_id, consume_data_stream_id]);

    return <>
        <Video brightness={brightness} track={consumers.find(c => c.type === "media")?.track} />
        <InnerContent>
            <Overlay
                irisPosNorth={irisPosNorth} irisPosEast={irisPosEast} irisPosDown={irisPosDown}
                irisAltitudePitch={irisAltitudePitch} irisAltitudeYaw={irisAltitudeYaw} irisAltitudeRoll={irisAltitudeRoll}
                irisVelX={irisVelX} irisVelY={irisVelY} irisVelZ={irisVelZ}
                irisGps={irisGps} irisMode={irisMode} setBrightness={setBrightness} producerChannel={producerChannel} manager={mediasoupManager} payloads={payloads} lockState={lockState} setLockState={setLockState} setStart={setStart} roll={roll}  pitch={pitch} yaw={yaw} robotIp={robotIp} pilotIp={pilotIp} latestMessage={latestMessage} instruction={instruction} gpsGoal={gpsGoal} battery={battery} distanceTravelled={distanceTravelled} left={localLeftRight} forward={localFwdBwd} heartbeat={heartbeat} />
        </InnerContent>
    </>
}