export const YELLOW1 = "#fec407"
export const YELLOW2 = "#f39c12"
export const WHITE1 = "white";
export const BLACK1 = "black"
export const GRAY1 = "gray";
export const GRAY2 = "#abb0ab";
export const RED1 = "#c05548";

// export const TEXT_COLOR      = "white";
export const TEXT_COLOR      = "#B8B8B8";
export const BUTTON_BG_COLOR = "rgba(255, 255, 255, 0.2)";
export const INPUT_BG_COLOR  = "rgba(255, 255, 255, 0.35)";
export const BOXLABEL_BG_COLOR = "gray";


