import {CirculatButton} from "./CirculatButton";
import {BLACK1, YELLOW1} from "./colors";
import {MediumText, SmallText} from "./Header";
import {VerySmallText} from "./NumericalScale";
import {SmallRing} from "./SmallRing";
import styled from "@emotion/styled";
const SmallRect = styled.div`
    background: white;
    height: 8px;
    width: 1px;
`

export const RightBarInner = ({setBrightness, circleBackground, manager, producerChannel}: any) => {
    return <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", flexDirection: "row-reverse"}}>
            <div>
                <div style={{display: "flex"}}>
                    <div style={{marginRight: 20}}  onClick={() => {
                        manager.sendData(JSON.stringify({type: "zoomin"}), producerChannel);
                        setBrightness((x: number) => Math.min(x + 10, 200));
                    }}>
                        <CirculatButton color={BLACK1} background={circleBackground} >
                            <div>+</div>
                        </CirculatButton>
                    </div>
                    <div onClick={() => {
                        manager.sendData(JSON.stringify({type: "zoomout"}), producerChannel);
                        setBrightness((x: number) => Math.max(x - 10, 0));
                    }}>
                    <CirculatButton color={BLACK1} background={circleBackground} >
                        <div>-</div>
                    </CirculatButton>
                    </div>
                    <SmallRect style={{marginLeft: 45, marginTop: 10}}/>
                </div>
                <div style={{paddingLeft: 10, marginTop: 20}}>
                    <SmallRing />
                </div>
                <div style={{paddingLeft: 12, marginTop: 15}}>
                    <MediumText>-</MediumText>
                </div>
            </div>
        </div>
    </div>
}