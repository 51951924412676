import {useEffect, useRef} from "react";
import {ThreeController} from "./ThreeController";
import {ThreePointCloudController} from "./ThreePointCloudController";

export const Video = ({track}: any) => {
    const videoRef = useRef(null)
    useEffect(() => {
        if (!videoRef || !videoRef.current) {
            return;
        }
        //@ts-ignore
        videoRef.current.srcObject = new MediaStream([track]);
        //@ts-ignore
        videoRef.current?.play();
        return () => {

        }
    }, [videoRef, track])


    return <div>
        <video ref={videoRef} autoPlay={true} playsInline={true}></video>
        {/*{videoRef && <ThreeController videoRef={videoRef} />}*/}
    </div>
}