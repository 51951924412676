// https://www.w3schools.com/css/tryit.asp?filename=trycss_grid

import { TEXT_COLOR } from "../colors_drone";


export const BottomLabels = ({ val_altitude, val_yaw, val_pos, val_vel, background_color, text_color, width, height }: { val_altitude: string; val_yaw: string; val_pos: string; val_vel: string; background_color: string; text_color: string; width: string; height: string }) => {
    return (
        <div style={grid_container_style}>
            <div style={grid_item_style}>
                <div style={top_label_style}> ALT </div>
                <div style={bottom_label_style}> {val_altitude} </div>
            </div>
            <div style={grid_item_style}>
                <div style={top_label_style}> HEADING </div>
                <div style={bottom_label_style}> {val_yaw} </div>
            </div>
            <div style={grid_item_style}>
                <div style={top_label_style}> POS </div>
                <div style={bottom_label_style}> {val_pos} </div>
            </div>
            <div style={grid_item_style}>
                <div style={top_label_style}> VEL </div>
                <div style={bottom_label_style}> {val_vel} </div>
            </div>
            
        </div>
    );
};

const grid_container_style = {
    display: "grid",
    gridTemplateColumns: "80px 80px 130px 130px",
    // backgroundColor: "#2196F3",
    padding: "0px",
    color: `${TEXT_COLOR}`,
    
};

const grid_item_style = {
    // backgroundColor: "rgba(255, 255, 255, 0.8)",
    // border: "0.5px solid rgba(0, 0, 0, 0.8)",
    margin: 2,
    paddingBottom: 0,
    paddingLeft: 4,
    height: "40px",
    textAlign: "left" as const,
    // borderRight:`1px solid ${GRAY1}`
    borderRight:"1px solid black",
    borderColor: `${TEXT_COLOR}`,
    
};

const top_label_style = {
    fontSize: 10,
    paddingTop: 4,
    paddingBottom: 4,
};

const bottom_label_style = {
    fontSize: 14,
    fontWeight: "bold",
    // fontWeight: "normal",
};
