import { useState } from "react";

export const InputboxRect = ({ background_color, text_color, width, default_value, onChange }: { background_color: string; text_color: string; width: string; default_value: string; onChange: any }) => {
    const [value, setValue] = useState(default_value);
    return (
            <input
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                    console.log("internal: val changed " + e.target.value);
                }}
                style={{
                    border: "none",
                    width: width,
                    background: background_color,
                    color: text_color,
                    height: "23px",
                    lineHeight: "23px",
                    borderTopStyle: "hidden",
                    borderBottomStyle: "hidden",
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderRadius: 0,
                    fontSize: 12,
                    // fontFamily: 'Roboto Mono',
                    opacity: "90%",
                    textAlign: "center",
                    outline: 'none'
                }}
                type={"number"}
            />
    );
};
