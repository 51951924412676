import {PlusIcon} from "./PlusIcon";
import styled from "@emotion/styled";
import {MediumText, SmallText} from "./Header";
import {BLACK1, WHITE1, YELLOW1} from "./colors";
import {Line} from "./Line";
import {NumericalScale, VerySmallText} from "./NumericalScale";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleUp, faArrowLeftLong, faMapMarker} from '@fortawesome/free-solid-svg-icons'
import {useStore} from "../components/hooks/useStore";
import {Keys} from "./Keys";

export const Rect = styled.div`
    background: white;
    height: 13px;
    width: 2px;
    margin-right: 3px;
`

export const LeftBar = ({setStart, roll, pitch, yaw, heartbeat, left, forward, battery, distanceTravelled, gpsGoal, instruction}: any) => {
    return <div>
        <div>
            <PlusIcon position={"right"}></PlusIcon>
        </div>
        <br/><br/><br/><br/><br/><br/>
        <div style={{marginLeft: 30}}>
        <div style={{display: "flex"}}>
            <Rect /><Rect /><Rect />
            <SmallText style={{marginTop: -2, marginLeft: 20}}>MENU</SmallText>
        </div>
        <div style={{marginTop: 20, marginLeft: 40}}>
            <MediumText>{instruction}</MediumText>
        </div>

        <div style={{marginTop: 20, marginLeft: 80, display: "flex"}}>
            <MediumText>HEARTBEAT: {heartbeat ? "OK" : "DISCONNECTED"}</MediumText>
            <div style={{marginLeft: 80, display: "flex"}}>
                <FontAwesomeIcon style={{marginTop: 3}} size={"xs"} icon={faMapMarker} color={YELLOW1} />
                <VerySmallText style={{marginTop: 6, marginLeft: 10}}>{gpsGoal}</VerySmallText>
            </div>
        </div>
        <div style={{marginTop: 25, marginLeft: 80, display: "flex"}}>
            <div style={{background: YELLOW1, paddingLeft: 3, paddingRight: 3}}>
                <FontAwesomeIcon size={"xs"} icon={faAngleDoubleUp} color={"black"} />
            </div>
            <SmallText style={{marginTop: 6, marginLeft: 10}}>Roll {roll}</SmallText>
            <SmallText style={{marginTop: 6, marginLeft: 10}}>Pitch {pitch}</SmallText>
            <SmallText style={{marginTop: 6, marginLeft: 10, color: YELLOW1}}>Yaw {yaw}</SmallText>
        </div>
        <br />
        <Line width={"97%"}></Line>
        <div style={{marginTop: 10, marginLeft: 40, width: 20, height: 45, background: YELLOW1, display: "flex"}}>
            <div style={{paddingTop: 20, paddingLeft: 6, color: BLACK1, fontSize: 15, marginRight: 20}}>
                <b>
                    &gt;
                </b>
            </div>
            <NumericalScale value={distanceTravelled} heading={"DISTANCE"} showEndSaparator={true} />
            <NumericalScale value={battery} heading={"BATTERY"} showEndSaparator={true} />
            <NumericalScale value={forward} heading={"FORWARD"} showEndSaparator={true} />
            <NumericalScale value={left} heading={"LEFT"} showEndSaparator={false} />
        </div>
        <div style={{display: "flex", marginTop: 40, cursor: "pointer"}} onClick={() => setStart(false)}>
            <FontAwesomeIcon size={"1x"} icon={faArrowLeftLong} color={"white"}  />
            <SmallText style={{marginLeft: 25}}>BACK</SmallText>
        </div>
        </div>
    </div>
}