import {useEffect, useState} from "react";
import {Signaling} from "../Signaling";
import {sfuUrl} from "../config";
import {MediasoupManager} from "../MediasoupManager";
import {Channel} from "../types/messages";
const DELAY_BETWEEN_MESSAGES = 0.1; // in seconds
const MAX_MESSAGES = 1000;

export const Benchmark = () => {
    const [avgValue, setAvgValue] = useState(0);

    useEffect(() => {
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9ucyI6W3siY2hhbm5lbCI6eyJ1c2VyX2lkIjoiMiIsInJvb21faWQiOiIyIiwicm9ib3RfaWQiOiIyIiwic3RyZWFtX2lkIjoiMSJ9LCJraW5kIjoicHJvZHVjZXIiLCJ0eXBlIjoiYWxsIn0seyJjaGFubmVsIjp7InVzZXJfaWQiOiIyIiwicm9vbV9pZCI6IjIiLCJyb2JvdF9pZCI6IjIiLCJzdHJlYW1faWQiOiIxIn0sImtpbmQiOiJjb25zdW1lciIsInR5cGUiOiJhbGwifV0sImlhdCI6MTY1NTkyMzQ0NH0.bj-pPhCrHs0QT2qcLW8JIPTiK1u46vOvNkzfg4rR57E"
        const sig = new Signaling(`${sfuUrl}?token=${token}`)
        const outgoingChannel = {
            user_id: "1",
            room_id: "1",
            robot_id: "1",
            stream_id: "1"
        };
        sig.setUsers = () => {}
        sig.onWsConnect = async() => {
            const manager = new MediasoupManager(sig);
            await manager.createSendTransport();
            await manager.createRecvTransport();

            manager.createDatachannel({
                user_id: "1",
                room_id: "1",
                robot_id: "1",
                stream_id: "1"
            });
            manager.consumeData({
                user_id: "1",
                room_id: "1",
                robot_id: "1",
                stream_id: "1"
            });

            let base = 0;
            let totalMessages = 0;
            manager.onData = (message: string) => {
                base += (Date.now() - parseInt(message));
                totalMessages += 1;
            }
            window.setInterval(() => {
                if (totalMessages !== 0)
                    setAvgValue(base / totalMessages);
            })
            for (let i = 0; i<MAX_MESSAGES; i++) {
                manager.sendData(Date.now() + "", outgoingChannel);
                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve("");
                    }, DELAY_BETWEEN_MESSAGES * 1000)
                })
            }
        }
    }, []);
    return <div>
        {avgValue}
    </div>
}