import {Button, Input} from 'reactstrap';
import {useCallback, useEffect, useState} from "react";
import {sfuUrl} from "../config";
import {Publish} from "./Publish";
import {Signaling} from "../Signaling";
import {Subscribe} from "./Subscribe";

interface Props {
    token: string;
    type: "publisher" | "subscriber";
}

export interface User {
    type: "data" | "media"; channel: {
        user_id: string;
        room_id: string;
        robot_id: string;
        stream_id: string;
    }
};

export const SFUConnection = ({token, type}: Props) => {
    const [signalingConnected, setSignalingConnected] = useState(false);
    const [signaling, setSignaling]: any = useState<Signaling>();
    const [users, setUsers] = useState<User[]>([]);

    const connect = useCallback(() => {
        if (token) {
            const sig = new Signaling(`${sfuUrl}?token=${token}`)
            sig.onWsConnect = () => {
                setSignalingConnected(true)
            }
            setSignaling(sig)
            sig.setUsers = (e: any) => {
                console.error(e);
                setUsers(e)
            }
        }
    }, [token]);

    const onClose = () => {
        signaling.destroy();
        setSignaling(null);
        setSignalingConnected(false)
    }

    return <div>
        {!signalingConnected && <Button color={"success"} onClick={connect}>Connect to SFU</Button>}
        {signalingConnected && "Connected to the SFU "}
        {signalingConnected && <Button color="secondary" onClick={onClose}>Hang up</Button>}
        <br/><br/>
        {signalingConnected && type === "publisher" && <Publish signaling={signaling} />}
        {signalingConnected && type === "subscriber" && <Subscribe users={users} signaling={signaling} />}
    </div>
}