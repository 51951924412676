import {useState} from "react";

export const InputBox = ({title, onClick}: any) => {
    const [value, setValue] = useState("");
    return <div style={{display: "flex", paddingBottom: 10}}>
        <div style={{width: "50%"}}>
            <input value={value} onChange={(e) => setValue(e.target.value)} style={{ width: "100px", background: "#3b403c", color: "#7e837d"}} type={"text"}/>
        </div>
        <div onClick={() => onClick(value)} style={{cursor: "pointer" , border: "2px solid black", justifyContent: "center", color: "#7e837d", width: "50%", background: "#323932"}}>
            <div>
                {title}
            </div>
        </div>
    </div>
}