import uuid from "uuidv4";
import {Channel, SendMessage, SendMessagInternal} from "./types/messages";

const SEND_AND_AWAIT_TIMEOUT = 6 * 1000;

export class Signaling {
    private _ws: WebSocket;
    private callbacks: {[key: string]: any};
    public setUsers: any; // function to set robots

    public onWsConnect?: () => void;
    public onWsStateChange?: (state: "closed" | "error") => void;
    constructor(sfuUrl: string) {
        const ws = new WebSocket(sfuUrl);
        this._ws = ws;
        this.callbacks = {};
        ws.onmessage = (event) => {
            let { data } = event;
            console.log(data);
            data = JSON.parse(data);
            if (data.callbackId) {
                this.callbacks[data.callbackId]?.(data.data);
            } else {
                this.handler(data);
            }
        }
        ws.onopen = () => {
            this.onWsConnect?.();
        }
        ws.onerror = () => {
            this.onWsStateChange?.("error");
        }
        ws.onclose = () => {
            this.onWsStateChange?.("closed");
        }
    }
    destroy() {
        this._ws.close();
    }
    async handler(data: any) {
        if (data.message === "current-users") {
            this.setUsers(data.payload.users)
        }
        if (data.message === "consume-try") {
            this.onConsumeTry(data.payload.channel, data.payload.type);
        }
        if (data.message === "log-message") {
            this.onLogMessage(data.payload.message);
        }
    }

    // Callback to be replaced in MediasoupManager
    onConsumeTry(channel: Channel, type: "video" | "data") {

    }

    onLogMessage(msg: string) {
        // alert(msg);
    }

    async sendAndAwait(data: any) {
        const callbackId = uuid();
        const outData = {
            callbackId,
            data: data
        }
        this._ws.send(JSON.stringify(outData));

        const promise = new Promise((resolve, reject) => {
            this.callbacks[callbackId] = resolve;
            window.setTimeout(() => {
                if (this.callbacks[callbackId]) {
                    reject("Request timed out while sending data " + JSON.stringify(data));
                }
            }, SEND_AND_AWAIT_TIMEOUT);
        });
        const response: any = await promise;
        return response;
    }
}