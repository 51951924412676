import styled from "@emotion/styled";
import AppIris from "./AppIris";
import { useEffect, useRef, useState } from "react";
import { Signaling } from "../Signaling";
import { sfuUrl, tokenUrl } from "../config";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { MediasoupManager } from "../MediasoupManager";
import { Channel } from "../types/messages";
import { Video } from "./Video";

const InnerContent = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
`;

export const IrisUI = ({ setStart, produce_user_id, produce_room_id, produce_robot_id, produce_stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_video_stream_id, consume_data_stream_id }: any) => {
    const [irisMode, setIrisMode] = useState("[ Mode ]");
    const [irisIsArmable, setIrisIsArmable] = useState("False");
    const [irisIsArmed, setIrisIsArmed] = useState("False");
    const [irisYaw, setIrisYaw] = useState("no");
    const [irisLastYawGoal, setIrisLastYawGoal] = useState<number>(0); // remember what the last set goal was either by button or key
    const [irisAltitude, setIrisAltitude] = useState("8.43");
    const [irisPosLocalNorth, setIrisPosLocalNorth] = useState("2.23");
    const [irisPosLocalEast, setIrisPosLocalEast] = useState("2.34");
    const [irisPosLocalDown, setIrisPosLocalDown] = useState("4.56");
    const [irisVelX, setIrisVelX] = useState("0.22");
    const [irisVelY, setIrisVelY] = useState("0.43");
    const [irisVelZ, setIrisVelZ] = useState("1.23");
    const [irisAttitudeRoll, setIrisAttitudeRoll] = useState("no");
    const [irisAttitudePitch, setIrisAttitudePitch] = useState("no");
    const [irisAttitudeYaw, setIrisAttitudeYaw] = useState("no");
    const [irisSystemStatus, setIrisSystemStatus] = useState("SYSTEMSTATUS:ACTIVE");
    const [irisBatteryVoltage, setIrisBatteryVoltage] = useState("12.24");
    const [irisBatteryCurrent, setIrisBatteryCurrent] = useState("25.5");
    const [irisBatteryLevel, setIrisBatteryLevel] = useState("100");
    const [irisGps, setIrisGps] = useState("GPSInfo:fix=3,num_sat=10");
    const [heartbeatIris, setHeartbeatIris] = useState("HEARTBEAT OK");

    const [robotIp, setRobotIp] = useState("");
    const [pilotIp, setPilotIp] = useState("127.0.0.1");
    const [instruction, setInstruction] = useState("");
    const [localLeftRight, setLocalLeftRight] = useState<number>(0);
    const [localFwdBwd, setLocalFwdBwd] = useState<number>(0);
    const [localSteer, setLocalSteer] = useState<number>(0);
    const [upDown, setUpDown] = useState<number>(0);
    const [lockState, setLockState] = useState(false);
    const [payloads, setPayloads] = useState([]);
    const [latestMessage, setLatestMessage] = useState("-");
    const [brightness, setBrightness] = useState(40);

    const [signaling, setSignaling]: any = useState<Signaling>();
    const [token, setToken] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [mediasoupManager, setMediasoupManager] = useState<MediasoupManager>();
    const [producerChannel, setProducerChannel] = useState({});
    const [consumers, setConsumers] = useState<{ type: "data" | "media"; channel: Channel; track?: MediaStreamTrack; textData?: string; pointCloudData?: string }[]>([]);

    const connect = (token: string, user_id: string, room_id: string, robot_id: string, stream_id: string, consume_user_id: string, consume_room_id: string, consume_robot_id: string, consume_data_stream_id: string, consume_video_stream_id: string) => {
        if (token) {
            const sig = new Signaling(`${sfuUrl}?token=${token}`);
            const outgoingChannel = {
                user_id: user_id,
                room_id: room_id,
                robot_id: robot_id,
                stream_id: stream_id,
            };
            sig.onWsConnect = async () => {
                setHeartbeatIris("HEARTBEAT OK");
                const manager = new MediasoupManager(sig);
                await manager.createSendTransport();
                await manager.createRecvTransport();

                manager.createDatachannel({
                    user_id,
                    room_id,
                    robot_id,
                    stream_id,
                });

                manager.consumeVideo({
                    user_id: consume_user_id,
                    room_id: consume_room_id,
                    robot_id: consume_robot_id,
                    stream_id: consume_video_stream_id,
                });

                manager.consumeData({
                    user_id: consume_user_id,
                    room_id: consume_room_id,
                    robot_id: consume_robot_id,
                    stream_id: consume_data_stream_id,
                });

                setProducerChannel({
                    user_id,
                    room_id,
                    robot_id,
                    stream_id,
                });

                window.setTimeout(async () => {
                    setPilotIp(await manager.getLocalIp());
                    setRobotIp(await manager.getRemoteIp());
                }, 5000);

                manager.onTrack = (track: any, channel: Channel) => {
                    setConsumers((v) => [...v.filter((x) => x.type !== "media"), { track, channel, type: "media", textData: "" }]);
                };

                manager.onDataConsumerCreated = (channel: Channel) => {
                    setConsumers((v) => [...v, { channel, type: "data", textData: "" }]);
                };

                manager.onData = (textData: string, channel: Channel) => {
                    try {
                        const updates = JSON.parse(textData);
                        updates.forEach(({ type, value }: any) => {
                            if (type === "iris_mode") {
                                // console.log("irismode", value)
                                setIrisMode(value);
                            }
                            if (type === "iris_altitude") {
                                // console.log("irismode", value)
                                setIrisAltitude(value);
                            }
                            if (type === "iris_yaw") {
                                setIrisYaw(value);
                            }
                            if (type === "iris_is_armable") {
                                setIrisIsArmable(value);
                            }
                            if (type === "iris_is_armed") {
                                setIrisIsArmed(value);
                            }
                            if (type === "iris_gps") {
                                setIrisGps(value);
                            }
                            if (type === "iris_vel_x") {
                                setIrisVelX(value);
                            }
                            if (type === "iris_vel_y") {
                                setIrisVelY(value);
                            }
                            if (type === "iris_vel_z") {
                                setIrisVelZ(value);
                            }
                            if (type === "iris_attitude_roll") {
                                setIrisAttitudeRoll(value);
                            }
                            if (type === "iris_attitude_pitch") {
                                setIrisAttitudePitch(value);
                            }
                            if (type === "iris_attitude_yaw") {
                                setIrisAttitudeYaw(value);
                            }
                            if (type === "iris_pos_local_north") {
                                setIrisPosLocalNorth(value);
                            }

                            if (type === "iris_pos_local_east") {
                                setIrisPosLocalEast(value);
                            }

                            if (type === "iris_pos_local_down") {
                                setIrisPosLocalDown(value);
                            }

                            if (type === "iris_battery_voltage") {
                                setIrisBatteryVoltage(value);
                            }
                            if (type === "iris_battery_current") {
                                setIrisBatteryCurrent(value);
                            }
                            if (type === "iris_battery_level") {
                                setIrisBatteryLevel(value);
                            }
                            if (type === "instruction") {
                                setInstruction(value);
                            }
                            if (type === "latestMessage") {
                                setLatestMessage(value);
                            }
                            if (type === "payloads") {
                                setPayloads(value);
                            }
                            if (type === "position") {
                                if (value.x) {
                                }
                                if (value.y) {
                                }
                            }
                        });
                    } catch (e) {
                        console.log("Error while parsing data" + e);
                    }
                };

                setMediasoupManager(manager);
                // document.addEventListener('keypress', (e) => {
                //     if (e.keyCode === 38) {
                //         // up-arrow
                //         setLocalCameraUpDown((t) => {
                //             manager.sendData(JSON.stringify({type: "camera_up_down", value: t + 0.1}), outgoingChannel);
                //             return parseFloat((t + 0.1).toFixed(1))
                //         })
                //     }
                //     if (e.key == '40') {
                //         //a
                //         setLocalCameraUpDown(t => {
                //             manager.sendData(JSON.stringify({type: "camera_up_down", value: t - 0.1}), outgoingChannel);
                //             return parseFloat((t - 0.1).toFixed(1))
                //         })
                //     }

                // if (e.key == "s") {
                //     //s
                //     setLocalForward(t => {
                //         manager.sendData(JSON.stringify({type: "top", value: t - 0.1}), outgoingChannel);
                //         return parseFloat((t - 0.1).toFixed(1))
                //     })
                //     manager.sendData(JSON.stringify({type: "down"}), outgoingChannel);
                // }

                // if (e.key == "d") {
                //     //d
                //     setLocalLeft(t => {
                //         manager.sendData(JSON.stringify({type: "left", value: t + 0.1}), outgoingChannel);
                //         return parseFloat((t + 0.1).toFixed(1))
                //     })
                // }
                // });

                document.addEventListener("keydown", (e) => {
                    if (e.key == "w" && !e.repeat) {
                        //w down
                        setLocalFwdBwd((t) => {
                            // manager.sendData(JSON.stringify({ type: "fwd_bwd", value: 1.0 }), outgoingChannel);
                            manager.sendData(JSON.stringify({ type: "set_velocity", velocity_goal_x: 1.0, velocity_goal_y: 0.0, velocity_goal_z: 0.0 }), outgoingChannel);

                            return parseFloat((1.0).toFixed(1));
                        });
                        console.log("w Down");
                    }

                    if (e.key == "s" && !e.repeat) {
                        //s down
                        setLocalFwdBwd((t) => {
                            // manager.sendData(JSON.stringify({ type: "fwd_bwd", value: -1.0 }), outgoingChannel);
                            manager.sendData(JSON.stringify({ type: "set_velocity", velocity_goal_x: -1.0, velocity_goal_y: 0.0, velocity_goal_z: 0.0 }), outgoingChannel);

                            return parseFloat((1.0).toFixed(1));
                        });
                        console.log("s Down");
                    }

                    if (e.key == "a" && !e.repeat) {
                        //a down
                        setLocalLeftRight((t) => {
                            // manager.sendData(JSON.stringify({ type: "left_right", value: -1.0 }), outgoingChannel);
                            manager.sendData(JSON.stringify({ type: "set_velocity", velocity_goal_x: 0.0, velocity_goal_y: -1.0, velocity_goal_z: 0.0 }), outgoingChannel);
                            return parseFloat((-1.0).toFixed(1));
                        });
                        console.log("a Down");
                    }

                    if (e.key == "d" && !e.repeat) {
                        //d down
                        setLocalLeftRight((t) => {
                            // manager.sendData(JSON.stringify({ type: "left_right", value: 1.0 }), outgoingChannel);
                            manager.sendData(JSON.stringify({ type: "set_velocity", velocity_goal_x: 0.0, velocity_goal_y: 1.0, velocity_goal_z: 0.0 }), outgoingChannel);

                            return parseFloat((1.0).toFixed(1));
                        });
                        console.log("d Down");
                    }

                    if (e.key == "q" && !e.repeat) { // Decrement yaw goal
                        //q down
                        setIrisLastYawGoal((t) => {
                            // manager.sendData(JSON.stringify({ type: "steer", value: 1.0 }), outgoingChannel);
                            manager.sendData(JSON.stringify({ type: "yaw", yaw_goal: t-10 }), outgoingChannel);
                            console.log("t = ", t)
                            return t-10;
                        });

                    }

                    if (e.key == "e" && !e.repeat) {
                        //e down
                        setIrisLastYawGoal((t) => {
                            // manager.sendData(JSON.stringify({ type: "steer", value: 1.0 }), outgoingChannel);
                            manager.sendData(JSON.stringify({ type: "yaw", yaw_goal: t+10 }), outgoingChannel);
                            console.log("t = ", t)
                            return t+10;
                        });
                        // setLocalSteer((t) => {
                        //     manager.sendData(JSON.stringify({ type: "steer", value: 1.0 }), outgoingChannel);
                        //     return parseFloat((1.0).toFixed(1));
                        // });
                        // console.log("e Steer");
                    }

                    if (e.key == "ArrowUp" && !e.repeat) {
                        //e down
                        setUpDown((t) => {
                            manager.sendData(JSON.stringify({ type: "up_down", value: 1.0 }), outgoingChannel);
                            return parseFloat((1.0).toFixed(1));
                        });
                        console.log("up arrow");
                    }

                    if (e.key == "ArrowDown" && !e.repeat) {
                        //e down
                        setUpDown((t) => {
                            manager.sendData(JSON.stringify({ type: "up_down", value: -1.0 }), outgoingChannel);
                            return parseFloat((1.0).toFixed(1));
                        });
                        console.log("down arrow");
                    }

                    if (e.key == "/" && !e.repeat) {
                        // / down
                        manager.sendData(JSON.stringify({ type: "camera_reset", value: 1 }), outgoingChannel);
                        console.log("/ Down");
                    }

                    if (e.key == "b" && !e.repeat) {
                        // b down
                        manager.sendData(JSON.stringify({ type: "beep", value: 1 }), outgoingChannel);
                        console.log("b Down");
                    }

                    // if (e.keyCode === 38) {
                    //     //up-arrow (repeating)
                    //     setLocalCameraUpDown((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_up_down", value: t + 0.1}), outgoingChannel);
                    //         // console.log(t + 0.1)
                    //         return parseFloat((t + 0.1).toFixed(1))
                    //     })
                    //     console.log('up-arrow Down')
                    //     console.log(localCameraUpDown)
                    // }

                    // if (e.keyCode === 40) {
                    //     //down-arrow (repeating)
                    //     setLocalCameraUpDown((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_up_down", value: t - 0.1}), outgoingChannel);
                    //         // console.log(t - 0.1)
                    //         return parseFloat((t - 0.1).toFixed(1))
                    //     })
                    //     console.log('down-arrow Down')
                    //     console.log(localCameraUpDown)
                    // }

                    // if (e.keyCode === 37) {
                    //     //up-arrow (repeating)
                    //     setLocalCameraLeftRight((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_left_right", value: t - 0.1}), outgoingChannel);
                    //         // console.log(t - 0.1)
                    //         return parseFloat((t - 0.1).toFixed(1))
                    //     })
                    //     console.log('left-arrow Down')
                    //     console.log(localCameraLeftRight)
                    // }

                    // if (e.keyCode === 39) {
                    //     //right-arrow (repeating)
                    //     setLocalCameraLeftRight((t) => {
                    //         manager.sendData(JSON.stringify({type: "camera_left_right", value: t + 0.1}), outgoingChannel);
                    //         console.log(t + 0.1)
                    //         return parseFloat((t + 0.1).toFixed(1))
                    //     })
                    //     console.log('right-arrow Down')
                    //     console.log(localCameraLeftRight)
                    // }
                });

                document.addEventListener("keyup", (e) => {
                    if (e.key == "w") {
                        // w up
                        setLocalFwdBwd((t) => {
                            manager.sendData(JSON.stringify({ type: "fwd_bwd", value: 0.0 }), outgoingChannel);
                            return parseFloat((0.0).toFixed(1));
                        });
                        console.log("w UP");
                    }

                    if (e.key == "s") {
                        // s up
                        setLocalFwdBwd((t) => {
                            manager.sendData(JSON.stringify({ type: "fwd_bwd", value: 0.0 }), outgoingChannel);
                            return parseFloat((0.0).toFixed(1));
                        });
                        console.log("s UP");
                    }

                    if (e.key == "a") {
                        // a up
                        setLocalLeftRight((t) => {
                            manager.sendData(JSON.stringify({ type: "left_right", value: 0.0 }), outgoingChannel);
                            return parseFloat((0.0).toFixed(1));
                        });
                        console.log("a UP");
                    }

                    if (e.key == "d") {
                        // d up
                        setLocalLeftRight((t) => {
                            manager.sendData(JSON.stringify({ type: "left_right", value: 0.0 }), outgoingChannel);
                            return parseFloat((0.0).toFixed(1));
                        });
                        console.log("d UP");
                    }

                    // if (e.key == "q") {
                    //     //q up
                    //     setLocalSteer((t) => {
                    //         manager.sendData(JSON.stringify({ type: "steer", value: 0.0 }), outgoingChannel);
                    //         return parseFloat((0.0).toFixed(1));
                    //     });
                    //     console.log("q Up");
                    // }

                    if (e.key == "e") {
                        //e up
                        setLocalSteer((t) => {
                            manager.sendData(JSON.stringify({ type: "steer", value: 0.0 }), outgoingChannel);
                            return parseFloat((0.0).toFixed(1));
                        });
                        console.log("e Up");
                    }

                    if (e.key == "b") {
                        //b up
                        manager.sendData(JSON.stringify({ type: "beep", value: 0 }), outgoingChannel);
                        console.log("b Up");
                    }

                    if (e.key == "]") {
                        setBrightness((x: number) => Math.min(x + 10, 200));
                    }

                    if (e.key == "[") {
                        setBrightness((x: number) => Math.max(x - 10, 0));
                    }
                });
            };
            setSignaling(sig);
            sig.setUsers = (e: any) => {};
            sig.onWsStateChange = () => {
                setHeartbeatIris("false");
            };
        }
    };

    const getToken = async (user_id: string, room_id: string, robot_id: string, stream_id: string, consume_user_id: string, consume_room_id: string, consume_robot_id: string, consume_data_stream_id: string, consume_video_stream_id: string) => {
        const response = await axios.post(`${tokenUrl}/api/v1/token`, {
            props: [
                {
                    kind: "producer",
                    channel: {
                        user_id,
                        room_id,
                        robot_id,
                        stream_id,
                    },
                    type: "stream",
                },
                {
                    kind: "consumer",
                    channel: {
                        user_id: consume_user_id,
                        room_id: consume_room_id,
                        robot_id: consume_robot_id,
                    },
                    type: "robot",
                },
            ],
        });
        connect(response.data.token, user_id, room_id, robot_id, stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_data_stream_id, consume_video_stream_id);
        setToken(response.data.token);
    };

    useEffect(() => {
        const user_id = produce_user_id;
        const room_id = produce_room_id;
        const robot_id = produce_robot_id;
        const stream_id = produce_stream_id;
        getToken(user_id, room_id, robot_id, stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_data_stream_id, consume_video_stream_id);
    }, [produce_user_id, produce_room_id, produce_robot_id, produce_stream_id, consume_user_id, consume_room_id, consume_robot_id, consume_video_stream_id, consume_data_stream_id]);

    return (
        <div style={{ background: "black", height: "100vh" }}>
            <Video brightness={brightness} track={consumers.find((c) => c.type === "media")?.track} />
            <InnerContent>
                <AppIris
                    irisMode={irisMode}
                    irisIsArmable={irisIsArmable}
                    irisIsArmed={irisIsArmed}
                    irisYaw={irisYaw}
                    irisAltitude={irisAltitude}
                    irisPosLocalNorth={irisPosLocalNorth}
                    irisPosLocalEast={irisPosLocalEast}
                    irisPosLocalDown={irisPosLocalDown}
                    irisVelX={irisVelX}
                    irisVelY={irisVelY}
                    irisVelZ={irisVelZ}
                    irisAttitudeRoll={irisAttitudeRoll}
                    irisAttitudePitch={irisAttitudePitch}
                    irisAttitudeYaw={irisAttitudeYaw}
                    irisSystemStatus={irisSystemStatus}
                    irisBatteryVoltage={irisBatteryVoltage}
                    irisBatteryCurrent={irisBatteryCurrent}
                    irisBatteryLevel={irisBatteryLevel}
                    irisGps={irisGps}
                    heartbeatIris={heartbeatIris}
                    robotIp={robotIp}
                    pilotIp={pilotIp}
                    instruction={instruction}
                    localLeftRight={localLeftRight}
                    localFwdBwd={localFwdBwd}
                    localSteer={localSteer}
                    upDown={upDown}
                    lockState={lockState}
                    payloads={payloads}
                    latestMessage={latestMessage}
                    brightness={brightness}
                    setBrightness={setBrightness}
                    producerChannel={producerChannel}
                    manager={mediasoupManager}
                />
            </InnerContent>
        </div>
    );
};
