import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {useCallback, useState} from "react";
import {SFUConnection} from "./SFUConnection";
import axios from "axios";
import {tokenUrl} from "../config";
import {TokenFetcher} from "./TokenFetcher";

export const UserPublisher = () => {
    const [token, setToken] = useState("");

    return <div>
        <h2>User Publisher</h2>
        <TokenFetcher kind={"producer"} setToken={setToken} token={token} />
        <br/><br/>
        {token && <SFUConnection type={"publisher"} token={token} />}
    </div>
}