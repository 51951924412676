import {Col, Container, Row} from "reactstrap";
import {Button, FormGroup, Input, Label} from "reactstrap";
import {useState} from "react";
import {IrisUI} from "./IrisUi";

export const IrisUiOuter = () => {
    const [start, setStart] = useState(false);
    const [produce_user_id, set_produce_user_id] = useState("1");
    const [produce_room_id, set_produce_room_id] = useState("1");
    const [produce_robot_id, set_produce_robot_id] = useState("1");
    const [produce_stream_id, set_produce_stream_id] = useState("1");

    const [consume_user_id, set_consume_user_id] = useState("2");
    const [consume_room_id, set_consume_room_id] = useState("2");
    const [consume_robot_id, set_consume_robot_id] = useState("2");
    const [consume_video_stream_id, set_consume_video_stream_id] = useState("1");
    const [consume_data_stream_id, set_consume_data_stream_id] = useState("2");

    return <div>
        {!start  && <><br/><br/><br/>
        <Container>
            <Row>
                <Col lg={"6"}>
                    <FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Producer User id</Label>
                            <Input value={produce_user_id} onChange={(e) => set_produce_user_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer User id" />
                        </FormGroup>

                        <FormGroup>
                            <Label for="exampleEmail">Producer Room id</Label>
                            <Input value={produce_room_id} onChange={(e) => set_produce_room_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer Room id" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Producer Robot id</Label>
                            <Input value={produce_robot_id} onChange={(e) => set_produce_robot_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer Robot id" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Producer Data Stream id</Label>
                            <Input value={produce_stream_id} onChange={(e) => set_produce_stream_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer Data Stream id" />
                        </FormGroup>
                    </FormGroup>
                </Col>
                <Col lg={"6"}>
                    <FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Consumer User id</Label>
                            <Input value={consume_user_id} onChange={(e) => set_consume_user_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer User id" />
                        </FormGroup>

                        <FormGroup>
                            <Label for="exampleEmail">Consumer Room id</Label>
                            <Input value={consume_room_id} onChange={(e) => set_consume_room_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer Room id" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Consumer Robot id</Label>
                            <Input value={consume_robot_id} onChange={(e) => set_consume_robot_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer Robot id" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Consumer Data Stream id</Label>
                            <Input value={consume_data_stream_id} onChange={(e) => set_consume_data_stream_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer Data Stream id" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Consumer Video Stream id</Label>
                            <Input value={consume_video_stream_id} onChange={(e) => set_consume_video_stream_id(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Producer Data Stream id" />
                        </FormGroup>
                    </FormGroup>
                </Col>

            </Row>
        </Container></>}

        {!start && <div style={{width: "100vw", height: "100vh", }}>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button color={"primary"} onClick={() => setStart(true)}>Join</Button>
            </div>
        </div>}

        {start && <IrisUI
            produce_user_id={produce_user_id}
            produce_room_id={produce_room_id}
            produce_robot_id={produce_robot_id}
            produce_stream_id={produce_stream_id}
            consume_user_id={consume_user_id}
            consume_room_id={consume_room_id}
            consume_robot_id={consume_robot_id}
            consume_video_stream_id={consume_video_stream_id}
            consume_data_stream_id={consume_data_stream_id}
            setStart={setStart}
        />}
    </div>
}