import {SmallText} from "./Header";
import {GRAY1} from "./colors";
import styled from "@emotion/styled";

export const VerySmallText = styled.div`
  color: white;
  font-size: 6px;
`

export const LargeText = styled.div`
  color: white;
  font-size: 23px;
`

export const NumericalScale = ({value, heading, showEndSaparator}: {
    value: number | string;
    heading: string;
    showEndSaparator: boolean;
}) => {
    return <div style={{ borderRight: showEndSaparator ? `1px solid ${GRAY1}` : 0, paddingLeft: 25, paddingRight: 25}}>
        <VerySmallText style={{color: GRAY1, textAlign: "center"}}>
            {heading}
        </VerySmallText>
        <div style={{marginTop: 4}}>
            <LargeText>{value}</LargeText>
        </div>
    </div>
}