import {Col, Container, Row} from "reactstrap";
import {UserPublisher} from "./UserPublisher";
import {ClientSubscriber} from "./ClientSubscriber";
import React from "react";

export const MainController = () => {
    return <div>
        <Container>
            <Row>
                <Col lg={"6"}>
                    <UserPublisher />
                </Col>
                <Col lg={"6"}>
                    <ClientSubscriber />
                </Col>
            </Row>
        </Container>
    </div>
}