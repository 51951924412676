import React, {useCallback, useEffect, useRef} from "react";
import * as THREE from "three"
// import { GUI } from './jsm/libs/lil-gui.module.min.js';

const VERTEX_SHADER =
    `
uniform sampler2D map;

			uniform float width;
			uniform float height;
			uniform float nearClipping, farClipping;

			uniform float pointSize;
			uniform float zOffset;

			varying vec2 vUv;

			const float XtoZ = 1.11146; // tan( 1.0144686 / 2.0 ) * 2.0;
			const float YtoZ = 0.83359; // tan( 0.7898090 / 2.0 ) * 2.0;

			void main() {

				vUv = vec2( position.x / width, position.y / height );

				vec4 color = texture2D( map, vUv );
				float depth = ( color.r + color.g + color.b ) / 3.0;

				// Projection code by @kcmic

				float z = ( 1.0 - depth ) * (farClipping - nearClipping) + nearClipping;

				vec4 pos = vec4(
					( position.x / width - 0.5 ) * z * XtoZ,
					( position.y / height - 0.5 ) * z * YtoZ,
					- z + zOffset,
					1.0);

				gl_PointSize = pointSize;
				gl_Position = projectionMatrix * modelViewMatrix * pos;

			}
`

const FRAGMENT_SHADER = `
   uniform sampler2D map;

   		varying vec2 vUv;

   		void main() {

   			vec4 color = texture2D( map, vUv );
   			gl_FragColor = vec4( color.r, color.g, color.b, 0.2 );

   		}

`

export const ThreeController = ({videoRef}: any) => {
    const containerRef = useRef(null);

    const setupThree = useCallback(() => {
        let geometry, mesh, material;

        const container = containerRef.current;

        let camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 10000 );
        camera.position.set( 0, 0, 500 );

        let scene = new THREE.Scene();
        let center = new THREE.Vector3();
        center.z = - 1000;

        const video = videoRef.current;

        // @ts-ignore
        const texture = new THREE.VideoTexture( video );
        texture.minFilter = THREE.NearestFilter;

        const width = 640, height = 480;
        const nearClipping = 850, farClipping = 4000;

        geometry = new THREE.BufferGeometry();

        const vertices = new Float32Array( width * height * 3 );

        for ( let i = 0, j = 0, l = vertices.length; i < l; i += 3, j ++ ) {

            vertices[ i ] = j % width;
            vertices[ i + 1 ] = Math.floor( j / width );

        }

        geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );

        material = new THREE.ShaderMaterial( {

            uniforms: {

                'map': { value: texture },
                'width': { value: width },
                'height': { value: height },
                'nearClipping': { value: nearClipping },
                'farClipping': { value: farClipping },

                'pointSize': { value: 2 },
                'zOffset': { value: 1000 }

            },
            vertexShader: VERTEX_SHADER,
            fragmentShader: FRAGMENT_SHADER,
            blending: THREE.AdditiveBlending,
            depthTest: false, depthWrite: false,
            transparent: true

        } );

        mesh = new THREE.Points( geometry, material );
        scene.add( mesh );

        //@ts-ignore
        // const gui = new GUI();
        // //@ts-ignore
        // gui.add( material.uniforms.nearClipping, 'value', 1, 10000, 1.0 ).name( 'nearClipping' );
        // //@ts-ignore
        // gui.add( material.uniforms.farClipping, 'value', 1, 10000, 1.0 ).name( 'farClipping' );
        // //@ts-ignore
        // gui.add( material.uniforms.pointSize, 'value', 1, 10, 1.0 ).name( 'pointSize' );
        // //@ts-ignore
        // gui.add( material.uniforms.zOffset, 'value', 0, 4000, 1.0 ).name( 'zOffset' );

        // @ts-ignore
        video.play();


        let renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        (container as any)?.appendChild( renderer.domElement );

        // mouse = new THREE.Vector3( 0, 0, 1 );
        //
        // document.addEventListener( 'mousemove', onDocumentMouseMove );
        //
        // //
        //
        // window.addEventListener( 'resize', onWindowResize );
        animate();
        function render() {

            // camera.position.x += ( mouse.x - camera.position.x ) * 0.05;
            // camera.position.y += ( - mouse.y - camera.position.y ) * 0.05;
            camera.lookAt( center );

            renderer.render( scene, camera );

        }

        function animate() {

            requestAnimationFrame( animate );

            render();

        }
    }, [containerRef, videoRef]);

    useEffect(() => {
        if (containerRef && containerRef.current) {
            setupThree()
        }
    }, [containerRef, setupThree])

    return <div ref={containerRef}>

    </div>
}