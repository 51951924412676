import styled from "@emotion/styled";

const Add = styled.div`
  width: 50px;
  height: 50px;
  color: #ccc;
  transition: color .25s;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 40px;
    margin-left: -5px;
    margin-top: -20px;
    border-left: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
  }
  ::before{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    margin-left: -25px;
    margin-top: 0px;
    border-top: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
  }
`
export const PlusIcon = ({position}: {position: "left" | "right"}) => {
    return <div style={{float: position}}>
        <Add />
    </div>
}