import * as mediasoup from "mediasoup-client";
import { Signaling } from "./Signaling";
import { DataProducer } from "mediasoup-client/lib/DataProducer";
import { Channel } from "./types/messages";
import { RtpCapabilities } from "mediasoup-client/lib/RtpParameters";
import { NumSctpStreams } from "mediasoup-client/lib/SctpParameters";
import { channelEquals } from "./utils";

export class MediasoupManager {
    private _signaling: Signaling;
    private _sendTransport?: mediasoup.types.Transport;
    private _recvTransport?: mediasoup.types.Transport;
    private _device?: mediasoup.Device;
    public onTrack: any;
    public onData: any;
    private _producers: { channel: Channel; dataProducer: mediasoup.types.Producer }[];
    private _dataProducers: { channel: Channel; dataProducer: mediasoup.types.DataProducer }[];

    // Gerald add
    private bytesPrev = 0;
    private timestampPrev = 0;

    constructor(signaling: Signaling) {
        this._signaling = signaling;
        this._producers = [];
        this._dataProducers = [];
        this._signaling.onConsumeTry = (channel, type) => {
            if (type === "video") {
                this.consumeVideo(channel);
            }
            if (type === "data") {
                this.consumeData(channel);
            }
        };
    }

    async sendVideo(channel: Channel, resolution?: string | null, aspectRatio?: string | null, a_device?: string | null) {
        // a_device can be nothing (in which case the default device will be used (webcam typically or could also randomly be usb or elgato), or "elgato")

        // ------------------------------------------------------------------------------
        // Gerald Add: get Elgato stream
        // https://stackoverflow.com/questions/49167384/choose-webcams-via-getusermedia
        // The above mediaTrack takes the default device (typically the laptop webcam)
        // ------------------------------------------------------------------------------
        // console.log("a_device = ", a_device);
        let mediaTrack = null;
        const enumerate_devices = await navigator.mediaDevices.enumerateDevices();
        console.log("enumerate_devices = ", enumerate_devices);

        if (a_device === "elgato") {
            // console.log('x = ', x.getVideoTracks() )
            const elgate_device_id = enumerate_devices.find((x) => x.label.includes("Elgato")).deviceId;

            // https://webrtchacks.com/getusermedia-resolutions-3/
            // https://webrtchacks.github.io/WebRTC-Camera-Resolution/
            // https://webrtc.github.io/samples/src/content/getusermedia/resolution/
            // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getSupportedConstraints?ref=blog.addpipe.com#result
            // chrome 127	Elgato HD60 X (0fd9:008a)	1312x738	16:9	1312x738	1312x738	pass
            // chrome 127	Elgato HD60 X (0fd9:008a)	984x738		4:3		984x738		984x738		pass
            // chrome 127	Elgato HD60 X (0fd9:008a)	738x738		1:1		738x738		738x738		pass
            // chrome 127	Elgato HD60 X (0fd9:008a)	1292x727	16:9	1292x727	1292x727	pass
            // chrome 127	Elgato HD60 X (0fd9:008a)	969x727		4:3		969x727		969x727		pass
            // chrome 127	Elgato HD60 X (0fd9:008a)	640x480		4:3		640x480		640x480		pass
            // chrome 127	Elgato HD60 X (0fd9:008a)	480x480		1:1		480x480		480x480		pass

            let w = 1312;
            let h = 738;
            if (resolution === "480p") {
                w = 640;
                h = 480;
            }

            const constraints = {
                audio: false,
                video: {
                    deviceId: elgate_device_id,
                    width: { exact: w },
                    height: { exact: h },
                },
            };

            const media_stream_elgato = await navigator.mediaDevices.getUserMedia(constraints);
            // console.log("media_stream_elgato = ", media_stream_elgato);
            const media_track_elgato = media_stream_elgato.getVideoTracks()[0];

            mediaTrack = media_track_elgato;
            // console.log("mediaTrack = ", mediaTrack);
        } else {
            // Gerald comment: This was here originally from Harkirat. Picks (random) default device
            console.log("webcam");
            const params = {};
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: resolution ? { advanced: [{ height: parseInt(resolution), aspectRatio: parseFloat(aspectRatio || "1.667") }] } : true });
            mediaTrack = mediaStream.getVideoTracks()[0];
            // console.log("mediaStream = ", mediaStream);
            // console.log("mediaStream.getVideoTracks() = ", mediaStream.getVideoTracks());
        }

        let settings = mediaTrack.getSettings();
        console.log("settings = ", settings);

        this._sendTransport?.produce({
            appData: {
                channel,
            },
            track: mediaTrack,
            // track: media_track_elgato,
        });
    }

    async createDatachannel(channel: Channel) {
        const producer = (await this._sendTransport?.produceData({
            ordered: false,
            maxRetransmits: 0,
            label: "bot",
            appData: {
                channel,
            },
        })) as DataProducer;
        this._dataProducers.push({
            channel,
            dataProducer: producer,
        });
        console.error("created dataproducer");
    }

    async sendData(data: string, channel: Channel) {
        const dataProducer = this._dataProducers.find((x) => channelEquals(x.channel, channel));
        dataProducer?.dataProducer?.send(data);
    }

    async consumeData(channel: Channel) {
        const consumeOptions = await this._signaling.sendAndAwait({
            message: "consumedata",
            payload: {
                channel: channel,
                // rtpCapabilities: this._device?.rtpCapabilities,
            },
        });
        if (consumeOptions.error) {
            console.log("Data stream not ready yet");
            return;
        }

        const consumer = await this._recvTransport?.consumeData(consumeOptions);
        consumer?.on("message", (data) => {
            this.onData(data, channel);
        });
        this.onDataConsumerCreated(channel);
    }

    onDataConsumerCreated(_channel: Channel) {}

    async consumeVideo(channel: Channel) {
        console.error("iside consume video");
        const consumeOptions = await this._signaling.sendAndAwait({
            message: "consume",
            payload: {
                channel: channel,
                rtpCapabilities: this._device?.rtpCapabilities as RtpCapabilities,
                kind: "video",
            },
        });
        if (consumeOptions.error) {
            console.log("Video stream not ready yet");
            return;
        }

        const consumer = await this._recvTransport?.consume(consumeOptions);
        this.onTrack(consumer?.track, channel);
    }

    async createDevice() {
        const device = new mediasoup.Device();
        const { routerRtpCapabilities } = await this._signaling.sendAndAwait({
            message: "fetch-rtp-capabilities",
            payload: {},
        });
        await device.load({ routerRtpCapabilities });
        this._device = device;
    }

    async createSendTransport() {
        if (!this._device) {
            await this.createDevice();
        }
        const recvInfo = await this._signaling.sendAndAwait({
            message: "create-transport",
            payload: {
                direction: "send",
                numSctpStreams: this._device?.sctpCapabilities.numStreams as NumSctpStreams,
            },
        });
        this._sendTransport = this._device?.createSendTransport(recvInfo);
        //@ts-ignore
        window.sendTransport = this._sendTransport;
        this._setSendTransportCallbacks();
    }

    async getLocalIp() {
        const stats = await this._recvTransport?.getStats();
        let ip = "";
        stats?.forEach((stat) => {
            if (stat.type === "local-candidate") {
                ip = (stat.address || "127.0.0.1") + ":" + stat.port;
            }
        });
        return ip;
    }

    async getRemoteIp() {
        const stats = await this._recvTransport?.getStats();
        let ip = "";
        stats?.forEach((stat) => {
            if (stat.type === "remote-candidate") {
                ip = (stat.address || "127.0.0.1") + ":" + stat.port;
            }
        });
        return ip;
    }

    // Gerald Add
    async getStats() {
        const stats = await this._recvTransport?.getStats();

        let w = 0;
        let h = 0;
        let fps = 0;
        let delay = 0;
        let bitrateee = 0;

        let i = 0;
        stats.forEach((report) => {
            if (report.type === "inbound-rtp" && report.kind === "video" && "frameHeight" in report) {
                console.log("i  = ", i);
                i += 1;
                console.log(report);
                // Log the frame rate
                // console.log(` ${report.frameWidth} x ${report.frameHeight} `);
                // console.log(report.framesPerSecond);
                // console.log(report.framesPerSecond);
                // console.log(report);

                // Calculate bitrate
                // full HD: 3500 - 6000 kbps
                // 720p 30fps: 2500-4000 kbps
                const now = report.timestamp;

                let bitrate = 0;
                // if (report.type === "inbound-rtp" && report.mediaType === "video" && 'frameHeight' in report) {
                const bytes = report.bytesReceived;
                if (this.timestampPrev) {
                    bitrate = (8 * (bytes - this.bytesPrev)) / (now - this.timestampPrev);
                    bitrate = Math.floor(bitrate);
                }
                this.bytesPrev = bytes;
                this.timestampPrev = now;
                // }
                console.log("\nbitrate = ", bitrate);

                const sss = `${report.frameWidth} x ${report.frameHeight} px | ${report.framesPerSecond} fps | ${report.totalInterFrameDelay} ms delay | bitrate ${bitrate} kbits/sec `;
                console.log("sss = ", sss);

                // return sss;
                console.log("before return");
                w = report.frameWidth;
                h = report.frameHeight;
                fps = report.framesPerSecond;
                delay = report.totalInterFrameDelay;
                bitrateee = bitrate;
                // return "yo" // { width: report.frameWidth, height: report.frameHeight, fps: report.framesPerSecond, delay: report.totalInterFrameDelay, bitrate: bitrate };
                console.log("after return");
            }
        });

        // return "hi";
        return { width: w, height: h, fps: fps, delay: delay, bitrate: bitrateee };
    }

    destroy() {
        this._signaling?.destroy();
    }

    async createRecvTransport() {
        if (!this._device) {
            await this.createDevice();
        }
        const recvInfo = await this._signaling.sendAndAwait({
            message: "create-transport",
            payload: {
                direction: "recv",
                numSctpStreams: this._device?.sctpCapabilities.numStreams as NumSctpStreams,
            },
        });
        this._recvTransport = this._device?.createRecvTransport(recvInfo);
        this._setRecvTransportCallbacks();
    }

    _setRecvTransportCallbacks() {
        this._recvTransport?.on("connect", ({ dtlsParameters }: { dtlsParameters: mediasoup.types.DtlsParameters }, onSuccess: () => void, onError: (err?: any) => void) => {
            this._signaling
                .sendAndAwait({
                    message: "transport-connect",
                    payload: {
                        transportId: this._recvTransport?.id || "",
                        dtlsParameters,
                    },
                })
                .then((res) => {
                    onSuccess();
                })
                .catch((e) => {
                    onError(e);
                });
        });
    }

    _setSendTransportCallbacks() {
        this._sendTransport?.on("connect", ({ dtlsParameters }: { dtlsParameters: mediasoup.types.DtlsParameters }, onSuccess: () => void, onError: (err?: any) => void) => {
            this._signaling
                .sendAndAwait({
                    message: "transport-connect",
                    payload: {
                        transportId: this._sendTransport?.id || "",
                        dtlsParameters,
                    },
                })
                .then((res) => {
                    onSuccess();
                })
                .catch((e) => {
                    onError(e);
                });
        });

        this._sendTransport?.on("produce", ({ kind, rtpParameters, appData }: { kind: mediasoup.types.MediaKind; rtpParameters: mediasoup.types.RtpParameters; appData: any }, onSuccess: (value: { id: string }) => void, onError: (err?: any) => void) => {
            this._signaling
                ?.sendAndAwait({
                    message: "produce",
                    payload: {
                        channel: appData.channel,
                        kind: "video",
                        rtpParameters,
                        // rtpParameters: RtpParameters
                        // transportId: this._sendTransport?.id || "",
                        // tag: "video",
                        // kind,
                        // ,
                        // streamId: "1", // TODO: Change these
                        // robotId: "2"
                    },
                })
                .then((res) => {
                    onSuccess(res);
                })
                .catch((e) => {
                    console.error(`sendWithResponse("produce") failed: ${e}`);
                    onError(e);
                });
        });

        this._sendTransport?.on("producedata", ({ sctpStreamParameters, label, protocol, appData }, onSuccess: (value: { id: string }) => void, onError: (err?: any) => void) => {
            this._signaling
                ?.sendAndAwait({
                    message: "producedata",
                    payload: {
                        channel: appData.channel,
                        sctpStreamParameters,
                        label,
                        protocol,
                        appData,
                    },
                })
                .then((res) => {
                    onSuccess(res);
                })
                .catch((e) => {
                    console.error(`sendWithResponse("produce") failed: ${e}`);
                    onError(e);
                });
        });
    }
}
