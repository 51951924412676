import {CirculatButton} from "./CirculatButton";
import {BLACK1, YELLOW1} from "./colors";
import {MediumText, SmallText} from "./Header";
import {VerySmallText} from "./NumericalScale";
import {SmallRing} from "./SmallRing";
import styled from "@emotion/styled";
const SmallRect = styled.div`
    background: white;
    height: 8px;
    width: 1px;
`

export const RightBarInner2 = ({payloads}: any) => {
    return <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", flexDirection: "row-reverse"}}>
            <div>
                <div style={{display: "flex"}}>
                    <MediumText style={{marginRight: 20}}>{payloads?.[0]}</MediumText>
                    <MediumText>{payloads.length}</MediumText>
                    <SmallRect style={{marginLeft: 45, marginTop: 10}}/>
                </div>
                <div style={{paddingLeft: 10, marginTop: 20}}>
                    <SmallRing />
                </div>
                <div style={{paddingLeft: 12, marginTop: 15}}>
                    <MediumText>-</MediumText>
                </div>
            </div>
        </div>
    </div>
}