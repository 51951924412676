import {useEffect, useRef} from "react";
import styled from "@emotion/styled";

const DivOuter = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  background: black;
`


const VideoOuter = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  //filter: grayscale();
  //    filter:brightness(40%);
`

export const Video = ({track, brightness}: any) => {
    const videoRef = useRef(null)
    useEffect(() => {
        if (!videoRef || !videoRef.current || !track) {
            return;
        }
        //@ts-ignore
        videoRef.current.srcObject = new MediaStream([track]);
        //@ts-ignore
        videoRef.current?.play();
        return () => {

        }
    }, [videoRef, track])

    if (!track) {
        return <DivOuter />
    }


    return <div>
        <VideoOuter style={{filter: `brightness(${brightness}%)`}} ref={videoRef} autoPlay={true} playsInline={true}></VideoOuter>
    </div>
}