
import { Container, Row, Col } from 'reactstrap';
import {Line} from "../new/Line";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleUp, faMapMarker} from "@fortawesome/free-solid-svg-icons";
import {YELLOW1} from "../new/colors";
import {VerySmallText} from "../new/NumericalScale";
import {MediumText, SmallText} from "../new/Header";

export const MiddleSection = ({irisGps, irisMode, gpsGoal, heartbeat, battery, roll, pitch, yaw}: any) => {
    return <div>
        <Row>
            <Col xs={"4"}>
                <div style={{color: "white"}}>

                </div>
            </Col>
            <Col xs={"4"} >
                <img src={'/semicircle.svg'} style={{width: "100%"}} />
                <div style={{display: "flex", justifyContent: "center"}}>
                    <MediumText style={{marginTop: "-25%", color: "white"}}>
                        {irisMode || "----"}
                    </MediumText>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <SmallText style={{marginTop: "-15%", marginLeft: "40%", color: "white"}}>
                        {irisGps}
                    </SmallText>
                </div>
            </Col>
            <Col xs={"4"}>
                <div style={{marginLeft: "20%", marginTop: "25%"}}>
                    <div style={{marginTop: 25, marginLeft: 80, display: "flex"}}>
                        <div style={{background: YELLOW1, paddingLeft: 3, paddingRight: 3}}>
                            <FontAwesomeIcon size={"xs"} icon={faAngleDoubleUp} color={"black"} />
                        </div>
                        <SmallText style={{marginTop: 6, marginLeft: 10}}>Roll {roll}</SmallText>
                        <SmallText style={{marginTop: 6, marginLeft: 10}}>Pitch {pitch}</SmallText>
                        <SmallText style={{marginTop: 6, marginLeft: 10, color: YELLOW1}}>Yaw {yaw}</SmallText>
                    </div>
                </div>
            </Col>
        </Row>
        <div>
            <Line width={"300%"}></Line>
            <Row style={{marginTop: 10}}>
                <Col xs={"4"}>
                </Col>

                <Col xs={"4"}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex"}}>
                            <FontAwesomeIcon style={{marginTop: 3}} size={"sm"} icon={faMapMarker} color={YELLOW1} />
                            <MediumText style={{marginTop: 6, marginLeft: 10}}>{gpsGoal || "-"}</MediumText>
                        </div>
                        <div>
                            <MediumText>HEARTBEAT: {heartbeat ? "OK" : "DISCONNECTED"}</MediumText>
                        </div>
                    </div>
                </Col>

                <Col xs={"4"}>
                    <div style={{display: "flex", width: 70, height: 30, background: "gray"}}>
                        <div style={{marginLeft: 5, marginTop: 2, display: "flex"}}>
                            <img src={"/battery.svg"} style={{background: "gray", color: "gray", width: 20}}  />
                            <div style={{marginLeft: 5, color: "white", fontSize: 12, marginTop: 6}}>
                                {battery}%
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        </div>

    </div>
}