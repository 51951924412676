import {PlusIcon} from "./PlusIcon";
import {Line} from "./Line";
import {RightBarInner} from "./RightBarInner";
import {BLACK1, WHITE1, YELLOW1} from "./colors";
import {Button} from "./Button";
import {RightBarInner2} from "./RightBarInner2";


export const RightBar = ({setBrightness, producerChannel, payloads, manager}: any) => {
    return <div>
        <PlusIcon position={"left"} />
        <br/><br/><br/><br/><br/><br/><br/><br/>
        <RightBarInner setBrightness={setBrightness} manager={manager} producerChannel={producerChannel} circleBackground={YELLOW1}/>
        <br/>
        <Line width={"97%"} />
        <br/>
        <RightBarInner2 payloads={payloads} circleBackground={WHITE1}/>
        <br/><br/><br/>
        <div onClick={() => {
            manager.sendData(JSON.stringify({type: "drop"}), producerChannel);
        }} style={{display: "flex", flexDirection: "row-reverse", marginRight: 50}}>
            <Button color={BLACK1} background={YELLOW1} text={"ALERT"}  />
        </div>
    </div>
}