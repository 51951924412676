/* @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap'); */

import "./rajdhani_font.css";

// import { TEXT_COLOR } from "../colors_drone";


export const BigAltitudeLabel = ({ val_altitude,  text_color}: { val_altitude: string; text_color: string }) => {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ color: text_color, border: "0px solid red"}}> ALT </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                <div style={{ fontSize: 80, color: text_color,fontFamily: 'Rajdhani', border: "0px solid red" }}> {val_altitude.split(".")[0]}. </div>
                <div style={{ fontSize: 30, color: text_color,fontFamily: 'Rajdhani' }}> {val_altitude.split(".")[1]} </div>
            </div>
        </div>
    );
};



