import styled from "@emotion/styled";
import {faCircleDot, faCircleH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Ring = ({x, y, ringCenterRef}: any) => {
    return <div>
        <div ref={ringCenterRef}>
            <img src={"/circle.png"} width={10} height={10}  />
        </div>
        <img src={"/ring.svg"} width={150} height={150} style={{opacity: 0.3}} />
    </div>
}
