import {SmallText} from "../new/Header";
import {Rect} from "../new/LeftBar";


export const Header = () => {
    return <div style={{display: "flex", justifyContent: "space-between", marginTop: 20}}>
        <div>
            <div style={{display: "flex", marginLeft: 20}}>
                <Rect style={{background: "gray"}} /><Rect style={{background: "gray"}} /> <SmallText style={{marginTop: -2, marginLeft: 20}}>0.0.1</SmallText>
            </div>
            <SmallText style={{marginTop: 10, marginLeft: 25}}>MENU</SmallText>
        </div>
        <div>
            <img src={'/barcode.svg'} width={150} style={{color: "gray", background: "gray"}} />
        </div>

    </div>
}