import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {Line} from "./Line";
import {GRAY1} from "./colors";
import {useStore} from "../components/hooks/useStore";

export const SmallText = styled.div`
  color: white;
  font-size: 9px;
`


export const MediumText = styled.div`
  color: white;
  font-size: 12px;
`

const SmallBreak = styled.div`
width: 100%;
height: 20px;
`

const SmallRect = styled.div`
    background: white;
    height: 14px;
    width: 10px;
`

export const Header = ({pilotIp, robotIp, latestMessage}: any) => {
    return <div>
        <SmallText style={{
            color: GRAY1
        }}>{latestMessage} </SmallText>
        <SmallText> {pilotIp} | {robotIp} </SmallText> <SmallBreak />
        <div style={{display: "flex"}}>
        <SmallText style={{marginRight: 12}}> 2.0.1 </SmallText>
        <SmallRect />
        </div><SmallBreak />
        <Line width={230}/>
    </div>
}