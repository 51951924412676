import {SmallText} from "./Header";
import {BLACK1, WHITE1} from "./colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleUp,
    faAngleUp,
    faArrowLeftLong,
    faArrowTrendUp,
    faArrowUp, faChevronLeft, faChevronRight,
    faChevronUp, faLock, faLockOpen
} from "@fortawesome/free-solid-svg-icons";
import {CirculatButton} from "./CirculatButton";
import styled from "@emotion/styled";
import {Ring} from "./Ring";
import {Line} from "./Line";
import {Keys} from "./Keys";

const BorderedRect = styled.div`
  width: 10px;
  height: 30px;
  border-radius: 2px;
  border: 2px solid #fec407;
`

const VerySmallRect = styled.div`
    background: white;
    height: 2px;
    width: 2px;
`

export const CenterBar = ({ringCenterRef, lockState, setLockState}: any) => {
    return <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", justifyContent: "center"}}>
            <SmallText>V_VE3-EL_E3-8</SmallText>
        </div>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <CirculatButton color={BLACK1} background={WHITE1}>
                <div style={{marginTop: 2}}>
                    <FontAwesomeIcon size={"1x"} icon={faChevronUp} color={"black"} />
                </div>
            </CirculatButton>
        </div>
        <br/><br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <SmallText>{lockState ? "LOCK" : "UNLOCK"}</SmallText>
        </div>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <BorderedRect />
        </div>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <FontAwesomeIcon size={"1x"} icon={faAngleDoubleUp} color={"white"} />
        </div>
        <br/>
        <div style={{display: "inline-flex", justifyContent: "center"}}>
            <div style={{display: "flex", marginTop: "55px", marginRight: 5}}>
                <div style={{marginRight: lockState ? 14 : 5, marginTop: -4, cursor: "pointer"}} onClick={() => setLockState(false)}>
                    <FontAwesomeIcon style={{fontSize: !lockState ? 20 : 15}} icon={faLockOpen} color={"white"} />
                </div>
                <FontAwesomeIcon style={{ cursor: "pointer"}} size={"1x"} icon={faChevronLeft} color={"white"} />
            </div>
            <div>
                <Ring ringCenterRef={ringCenterRef} x={0} y={0}/>
            </div>
            <div style={{display: "flex", marginTop: "55px", marginLeft: 5}}>
                <div style={{marginRight: 10, marginTop: -4, cursor: "pointer"}}>
                    <FontAwesomeIcon size={"1x"} icon={faChevronRight} color={"white"} />
                </div>
                <div onClick={() => setLockState(true)} style={{cursor: "pointer", marginTop: -4}}>
                    <FontAwesomeIcon style={{fontSize: lockState ? 20 : 15}} icon={faLock} color={"white"} />
                </div>
            </div>
        </div>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <SmallText>PILOT ID</SmallText>
        </div>
        <br/><br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <Line height={2} width={"30%"} />
        </div>
        <br/>
        <Keys />
        <br/><br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <VerySmallRect style={{marginRight: 20}}/>
            <VerySmallRect style={{marginRight: 20}}/>
            <VerySmallRect style={{marginRight: 20}}/>
            <VerySmallRect />
        </div>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <VerySmallRect style={{marginRight: 20}}/>
            <VerySmallRect />
        </div>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
            <VerySmallRect style={{marginRight: 60}}/>
            <VerySmallRect />
        </div>

    </div>
}