import styled from "@emotion/styled";
import {useEffect, useState} from "react";
import {RED1, GRAY2} from "./colors";

const Key = styled.div`
  padding: 1px 15px 1px 15px;
  border-radius: 4px;
  margin: 2px;
  width: 30px;
  font-size: 12px;
`
export const Keys = () => {
    const [keysPressed, setKeysPressed] = useState<string[]>([]);
    const keyDownHandler = (e: any) => {
        setKeysPressed((x) => [...x, e.key]);
    }

    const keyUpHandler = (e: any) => {
        setKeysPressed((x) => x.filter(y => y !== e.key));
    }

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler)
        document.addEventListener("keyup", keyUpHandler)
        return () => {
            document.removeEventListener("keydown", keyDownHandler)
            document.removeEventListener("keyup", keyUpHandler)
        }
    });

    return <>
        <div style={{display: "flex", justifyContent: "center"}}>
            <Key style={{display: "flex", justifyContent: "center", background: keysPressed.includes('q') ? RED1 : GRAY2}}>
                Q
            </Key>
            <Key style={{display: "flex", justifyContent: "center", background: keysPressed.includes('w') ? RED1 : GRAY2}}>
                W
            </Key>
            <Key style={{display: "flex", justifyContent: "center", background: keysPressed.includes('e') ? RED1 : GRAY2}}>
                E
            </Key>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <Key style={{display: "flex", justifyContent: "center", background: keysPressed.includes('a') ? RED1 : GRAY2}}>
                A
            </Key>
            <Key style={{display: "flex", justifyContent: "center", background: keysPressed.includes('s') ? RED1 : GRAY2}}>
                S
            </Key>
            <Key style={{display: "flex", justifyContent: "center", background: keysPressed.includes('d') ? RED1 : GRAY2}}>
                D
            </Key>
        </div>
    </>
}