import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    BrowserRouter, Link,
    Routes,
    Route
} from "react-router-dom";
import {MainController} from "./components/MainController";
import {ThreeController} from "./components/ThreeController";
import {NewUi} from "./new/NewUi";
import {NewUiOuter} from "./new/NewUiOuter";
import {Benchmark} from "./benchmark/Benchmark";
import {PiCam} from "./picam/PiCam";
import {DroneUiOuter} from "./drone/DroneUiOuter";
import {IrisUiOuter} from "./iris/IrisUiOuter";
import {FpvUiOuter} from "./fpv/FpvUiOuter";
// import AppIris from "./iris/AppIris";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<MainController />} />
              <Route path="/3d" element={<ThreeController />} />
              <Route path="/benchmark" element={<Benchmark />} />
              <Route path="/picam" element={<PiCam />} />
              <Route path="/new" element={<NewUiOuter />} />
              <Route path="/drone" element={<DroneUiOuter />} />
              <Route path="/iris" element={<IrisUiOuter />} />
              <Route path="/fpv" element={<FpvUiOuter />} />
              <Route path="/demo" element={<FpvUiOuter />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
