import {Button, Input} from "reactstrap";
import {useCallback, useEffect, useState} from "react";
import {MediasoupManager} from "../MediasoupManager";
import {Signaling} from "../Signaling";
import {Channel} from "../types/messages";

interface Props {
    signaling: Signaling;
}

export const Publish = ({ signaling }: Props) => {
    const [mediasoupManager, setMediasoupManager]: any = useState();
    const [textData, setTextData] = useState("");
    // const [currentlyProducing, setCurrentlyProducing] = useState("");
    const [channel, setChannel] = useState({
        user_id: "",
        robot_id: "",
        room_id: "",
        stream_id: ""
    });

    const [producers, setProducers] = useState<{type: "data" | "video", channel: Channel}[]>([]);

    useEffect(() => {
        const manager = new MediasoupManager(signaling);
        manager.createSendTransport();
        setMediasoupManager(manager)
    }, [signaling]);

    const sendVideo = useCallback(() => {
        mediasoupManager.sendVideo(channel);
        setProducers(p => [...p, {
            type: "video",
            channel
        }])
        setChannel({
            user_id: "",
            robot_id: "",
            room_id: "",
            stream_id: ""
        })
    }, [mediasoupManager, channel])

    const startDatachannel = useCallback(() => {
        mediasoupManager.createDatachannel(channel)
        setProducers(p => [...p, {
            type: "data",
            channel
        }])
        setChannel({
            user_id: "",
            robot_id: "",
            room_id: "",
            stream_id: ""
        })
    }, [mediasoupManager, channel])

    const sendData = useCallback((channel) => {
        mediasoupManager.sendData(JSON.stringify({
            data: textData,
            type: "text"
        }), channel)
    }, [textData, mediasoupManager])

    const sendRandomData = useCallback((channel) => {
        const data = [];
        for (let i = 0; i<400; i++) {
            data.push([Math.ceil(Math.random() * 2000) , Math.ceil(Math.random() * 2000) , Math.ceil(Math.random() * 2000) ])
        }
        mediasoupManager.sendData(JSON.stringify({data, type: "pointcloud"}), channel)
    }, [mediasoupManager]);

    const getTextFromChannel = (channel: Channel) => {
        return <div>
            User - {channel.user_id}
            Room - {channel.room_id}
            Robot - {channel.robot_id}
            Stream - {channel.stream_id}
        </div>
    }

    return <div>
        <Input value={channel.user_id} onChange={(e) => setChannel((c) => ({...c, user_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="User id" />
        <Input value={channel.room_id} onChange={(e) => setChannel((c) => ({...c, room_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="Room id" />
        <Input value={channel.robot_id} onChange={(e) => setChannel((c) => ({...c, robot_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="Robot id" />
        <Input value={channel.stream_id} onChange={(e) => setChannel((c) => ({...c, stream_id: e.target.value}))} type="text" name="text" id="exampleEmail" placeholder="Stream id" />
        <Button  color={"success"} onClick={sendVideo}>Produce Video</Button>
        <Button color={"success"} onClick={startDatachannel}>Produce data</Button>

        {producers.map(producer => <>
            {producer.type === "video" && <>
                {getTextFromChannel(producer.channel)}
            </>}
            <br/><br/>
            {producer.type === "data" && <>
                {getTextFromChannel(producer.channel)}
                <Input onChange={(e) => setTextData(e.target.value)} type={"textarea"}></Input>
                <br/>
                <Button color={"success"} onClick={() => sendData(producer.channel)}>Send data</Button>
                <Button color={"success"} onClick={() => sendRandomData(producer.channel)}>Send random data</Button>
            </>}
        </>)}
    </div>
}