import React from "react";
import background from "./assets/HUD_v04.svg";
import background_hud_green from "./assets/HUD_v04_less_green.svg";
import background_hud_no_bg from "./assets/HUD_v04_less_no_bg.svg";
import background_hud_no_bg_v2 from "./assets/HUD_v04_less_no_bg_v2.svg";
import background_silas_baisch from "./assets/silas-baisch-unsplash_crop.jpg";
import background_kyle_glenn from "./assets/kyle-glenn-unsplash_crop.jpg";

import svg_badge_circle from "./assets/badge_circle.svg";
import svg_plus_circle from "./assets/plus_circle.svg";
import svg_minus_circle from "./assets/minus_circle.svg";
import svg_battery from "./assets/battery.svg";
import svg_vhamburger from "./assets/vhamburger.svg";
import svg_pin from "./assets/pin.svg";

import { TEXT_COLOR, BOXLABEL_BG_COLOR } from "./colors_drone";

import { CommandTable } from "./components/CommandTable";
import { ButtonSvg } from "./components/ButtonSvg";
import { BoxLabels } from "./components/BoxLabels";
import { BottomLabels } from "./components/BottomLabels";
import { Arrows } from "./components/Arrows";
import { Arrows2 } from "./components/Arrows2";
import { BigAltitudeLabel } from "./components/BigAltitudeLabel";

import useWindowSize from "./useWindowSize";

import "./AppFpv.css";

import { useEffect, useState } from "react";

function AppFpv(
{
    fpvMode,
    fpvIsArmable,
    fpvIsArmed,
    fpvYaw,
    fpvAltitude,
    fpvPosLocalNorth,
    fpvPosLocalEast,
    fpvPosLocalDown,
    fpvVelX,
    fpvVelY,
    fpvVelZ,
    fpvAttitudeRoll,
    fpvAttitudePitch,
    fpvAttitudeYaw,
    fpvSystemStatus,
    fpvSensitivity,
    fpvGamepadYaw,
    fpvGamepadThrottle,
    fpvGamepadRoll,
    fpvGamepadPitch,
    fpvBatteryVoltage,
    fpvBatteryCurrent,
    fpvBatteryLevel,
    fpvGps,
    heartbeatFpv,
    robotIp,
    pilotIp,
    instruction,
    localLeftRight,
    localFwdBwd,
    localSteer,
    upDown,
    lockState,
    payloads,
    latestMessage,
    brightness,
    setBrightness,
    producerChannel,
    manager
} : any
) {

    const windowSize = useWindowSize();
    const [keysPressed, setKeysPressed] = useState<string[]>([]); // TODO: remove 'd', just for testing purposes

    const keyDownHandler = (e: any) => {
        setKeysPressed((x) => [...x, e.key]);
    }

    const keyUpHandler = (e: any) => {
        setKeysPressed((x) => x.filter(y => y !== e.key));
    }

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler)
        document.addEventListener("keyup", keyUpHandler)
        return () => {
            document.removeEventListener("keydown", keyDownHandler)
            document.removeEventListener("keyup", keyUpHandler)
        }
    });

    return (
        <div className="AppFpv">
            {/* -------------------------------- */}
            {/* Background Image */}
            {/* -------------------------------- */}
            <div style={{ position: "absolute", zIndex: "0" }}>
                {/* <img src={background} style={style_img_background} alt="logo" /> */}
                {/* <img src={background_silas_baisch} style={style_img_background} alt="logo" /> */}
                {/* <img src={background_kyle_glenn} style={style_img_background} alt="logo" /> */}
            </div>

            {/* -------------------------------- */}
            {/* HUD Overlay */}
            {/* -------------------------------- */}
            <div style={{ position: "absolute", zIndex: "1", filter: "invert(100%) opacity(90%)" }}>
                {/* <img src={background_hud_green} style={style_img_background} alt="logo" /> */}
                {/* <img src={background_hud_no_bg} style={style_img_background} alt="logo" /> */}
                <img src={background_hud_no_bg_v2} style={style_img_background} alt="logo" />
            </div>
            {/* Roll Pitch Yaw */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: (2210 / 3840) * windowSize.width, top: windowSize.width / 2.16 / 2.37037 }}>
                <p style={label_style}>R{fpvAttitudeRoll} P{fpvAttitudePitch} Y{fpvAttitudeYaw}</p>
                {/* <div className="text-red-600">Hiiiiiii</div> */}
            </div>
            {/* Battery */}
            <div style={{ display: "flex", alignItems: "center", position: "absolute", border: "0px solid red", zIndex: "2", left: (2830 / 3840) * windowSize.width, top: windowSize.width / 2.16 / 2.37037 }}>
                <img src={svg_battery} style={{ height: "10px", filter: "invert(100%) opacity(75%)", paddingRight: "8px" }} alt="logo" />
                <p style={label_style}>{fpvBatteryLevel + "% " + fpvBatteryVoltage + "V " + fpvBatteryCurrent + "A"}</p>
            </div>
            {/* Mode */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 1.39, top: windowSize.width / 6.3 }}>
                <p style={{ ...label_style, fontSize: "15px" }}>{fpvMode}</p>
            </div>
            {/* System Status */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 1.26, top: windowSize.width / 5.95 }}>
                <p style={{ ...label_style, fontSize: "8px" }}>{fpvSystemStatus}</p>
            </div>
            {/* Sensitivity */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 1.26, top: windowSize.width / 5.75 }}>
                <p style={{ ...label_style, fontSize: "8px" }}>SENSITIVITY: {fpvSensitivity}</p>
            </div>
            {/* Gamepad */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 1.26, top: windowSize.width / 5.55 }}>
                <p style={{ ...label_style, fontSize: "8px" }}>GAMEPAD: T{fpvGamepadThrottle} Y{fpvGamepadYaw} P{fpvGamepadPitch} R{fpvGamepadRoll}</p>
            </div>
            {/* GPS Info */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 1.26, top: windowSize.width / 6.3 }}>
                <p style={{ ...label_style, fontSize: "12px" }}>{fpvGps}</p>
            </div>
            {/* Roll Pitch Yaw */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: 0.275 * windowSize.width, top: windowSize.width / 2.16 / 2.37037 }}>
                <p style={{ ...label_style, fontSize: "12px" }}>{heartbeatFpv}</p>
            </div>
            {/* Pilot ID */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: 0.275 * windowSize.width, top: 0.17 * windowSize.width }}>
                <p style={{ ...label_style, fontSize: "11px" }}>PILOT ID</p>
            </div>
            {/* Screen Size */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: 0.275 * windowSize.width, top: 0.178 * windowSize.width }}>
                
            {/* Width: {windowSize.width}, Height:{windowSize.height} */}
                <p style={{ ...label_style, fontSize: "8px" }}>{windowSize.width} {windowSize.height}</p>
            </div>
            {/* Pilot IP */}
            <div style={{ display: "flex", alignItems: "center", position: "absolute", border: "0px solid red", zIndex: "2", left: 0.38 * windowSize.width, top: 0.215 * windowSize.width }}>
                <img src={svg_pin} style={{ height: "12px", filter: "invert(0%) opacity(100%)", paddingRight: "8px" }} />
                <p style={{ ...label_style, fontSize: "9px" }}>Pilot IP {pilotIp}</p>
            </div>
            {/* Robot IP */}
            <div style={{ display: "flex", alignItems: "center", position: "absolute", border: "0px solid red", zIndex: "2", left: 0.38 * windowSize.width, top: 0.227 * windowSize.width }}>
                <img src={svg_pin} style={{ height: "12px", filter: "invert(0%) opacity(100%)", paddingRight: "8px" }} />
                <p style={{ ...label_style, fontSize: "9px" }}>Robot IP {robotIp}</p>
            </div>
            {/* Menu */}
            <div style={{ display: "flex", alignItems: "center", position: "absolute", border: "0px solid red", zIndex: "2", left: 0.02 * windowSize.width, top: 0.02 * windowSize.width }}>
                <img src={svg_vhamburger} style={{ height: "15px", filter: "invert(100%) opacity(75%)", paddingRight: "8px" }} />
                <p style={{ ...label_style, fontSize: "15px" }}>MENU</p>
            </div>

            {/* ButtonSVG Badge + */}
            <div
                onClick={() => {
                    console.log("Button BADGE Clicked ");
                }}
                style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: 0.69 * windowSize.width, top: 0.16 * windowSize.width }}
            >
                <ButtonSvg file={svg_badge_circle} size="22px" invert_color_by="90%" opacity_default="80%" opacity_hover="90%" opacity_click="100%" />
            </div>
            {/* ButtonSVG Plus + */}
            <div
                onClick={() => {
                    setBrightness((x: number) => Math.min(x + 10, 200));
                    console.log("Button PLUS Clicked ");
                }}
                style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: 0.67 * windowSize.width, top: 0.147 * windowSize.width }}
            >
                <ButtonSvg file={svg_plus_circle} size="22px" invert_color_by="90%" opacity_default="80%" opacity_hover="90%" opacity_click="100%" />
            </div>
            {/* ButtonSVG Minus + */}
            <div
                onClick={() => {
                    console.log("Button MINUS Clicked ");
                    setBrightness((x: number) => Math.max(x - 10, 0));
                }}
                style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: 0.67 * windowSize.width, top: 0.173 * windowSize.width }}
            >
                <ButtonSvg file={svg_minus_circle} size="22px" invert_color_by="90%" opacity_default="80%" opacity_hover="90%" opacity_click="100%" />
            </div>

            {/* -------------------- */}
            {/* CommandTabls */}
            {/* -------------------- */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 7.5, top: windowSize.width / 4.65 }}>
                <CommandTable dummy="dummy" manager={manager} producerChannel={producerChannel} />
            </div>

            {/* -------------------- */}
            {/* BoxLabels */}
            {/* -------------------- */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 1.38, top: windowSize.width / 4.6 }}>
                <BoxLabels val_armable={fpvIsArmable} val_armed={fpvIsArmed} />
            </div>

            {/* -------------------- */}
            {/* BottomLabels */}
            {/* -------------------- */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: windowSize.width / 1.48, top: windowSize.width / 2.56 }}>
                <BottomLabels val_altitude={fpvAltitude} val_yaw={fpvYaw} val_pos={fpvPosLocalNorth + " " + fpvPosLocalEast + " " + fpvPosLocalDown} val_vel={fpvVelX + " " + fpvVelY + " " + fpvVelZ} width="90px" height="50px" text_color={TEXT_COLOR} background_color={BOXLABEL_BG_COLOR} />
            </div>

            {/* -------------------- */}
            {/* Arrows */}
            {/* -------------------- */}
            <div style={{ display: "flex", position: "absolute", border: "0px solid red", zIndex: "2", left: 0.01 * windowSize.width, top: 0.36 * windowSize.width }}>
                <div style={{ marginLeft: 0 }}>
                    <Arrows keysPressed={keysPressed} />
                </div>
                <div style={{ marginLeft: 60 }}>
                    <Arrows2 keysPressed={keysPressed} />
                </div>
            </div>

            {/* -------------------- */}
            {/* BigAltitudeLabel */}
            {/* -------------------- */}
            <div style={{ position: "absolute", border: "0px solid red", zIndex: "2", left: 0.47 * windowSize.width, top: 0.08 * windowSize.width }}>
                <BigAltitudeLabel val_altitude={fpvAltitude} text_color={TEXT_COLOR} />
            </div>

            {/* <div style={{ position: "absolute", zIndex: "2" }}>
                Width: {windowSize.width}, Height:{windowSize.height}
            </div> */}
        </div>
    );
}

// Styling: CSS in JS - To display  side-by-side
const style_img_background = {
    width: "100%",
};

const label_style = {
    // color: "red",
    color: `${TEXT_COLOR}`,
    zIndex: 9,
    fontSize: "12px",
    // fontWeight: "normal",
    fontWeight: "bold",
    margin: "0",
    border: "0px solid blue",
    // position: "absolute",
    // fontFamily: 'Rajdhani'
};

export default AppFpv;
