import {useEffect, useRef, useLayoutEffect} from "react";
import styled from "@emotion/styled";

const DivOuter = styled.video`
  position: fixed;
  right: 0;
  top: 0px;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  background: black;
`


const VideoOuter = styled.video`
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 0;
  //filter: grayscale();
  //    filter:brightness(40%);
`

export const Video = ({track, brightness}: any) => {
    const videoRef = useRef(null)
    const outerRef = useRef(null)

    useEffect(() => {
        if (!videoRef || !videoRef.current || !track) {
            return;
        }
        //@ts-ignore
        videoRef.current.srcObject = new MediaStream([track]);
        //@ts-ignore
        videoRef.current?.play();
        return () => {

        }
    }, [videoRef, track])

    useLayoutEffect(() => {
        const updateSize = () => {
            const videoHeight = window.innerWidth / (window.innerWidth < 1100 ? 2.15 : 2.34);
            if (videoRef.current) {
                //@ts-ignore
                // console.error(-(videoRef.current.clientHeight - videoHeight) / 2)
                //@ts-ignore
                videoRef.current.style["top"] =  -(videoRef.current.clientHeight - videoHeight) / 2 + "px"
            }
            if (outerRef.current) {
                //@ts-ignore
                outerRef.current.style["max-height"] = videoHeight + "px"
                //@ts-ignore
                outerRef.current.style["overflow"] = "hidden";
            }
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        window.setTimeout(() => {
            updateSize()
        }, 1000)
        return () => window.removeEventListener("resize", updateSize);
    }, [videoRef]);


    if (!track) {
        return <DivOuter />
    }

    return <div style={{position: "fixed", top: 0}} ref={outerRef}>
        <VideoOuter style={{filter: `brightness(${brightness}%)`}} ref={videoRef} autoPlay={true} playsInline={true}></VideoOuter>
    </div>
}