import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {tokenUrl} from "../config";
import {SFUConnection} from "./SFUConnection";
import {TokenFetcher} from "./TokenFetcher";

export const ClientSubscriber = () => {
    const [token, setToken] = useState("");

    return <div>
        <h2>Client Subscriber</h2>
        <TokenFetcher kind={"consumer"} setToken={setToken} token={token} />
        {token && <SFUConnection type={"subscriber"} token={token} />}
    </div>
}