import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {tokenUrl} from "../config";

export const TokenFetcher = ({kind, setToken, token}: {kind: string, setToken: any, token: string}) => {
    const [type, setType] = useState("all");
    const [user, setUser] = useState("");
    const [room, setRoom] = useState("");
    const [stream, setStream] = useState("");
    const [robot, setRobot] = useState("");

    const getToken = useCallback(async() => {
        const response = await axios.post(`${tokenUrl}/api/v1/token`, {
            props: [{
                kind: kind,
                channel: {
                    user_id: type !== "all" ? user : undefined,
                    room_id: (type !== "all" && type !== "user") ? room : undefined,
                    robot_id: type !== "all" && type !== "user" && type !== "room" ? robot : undefined,
                    stream_id: type === "stream" ? stream : undefined
                },
                type
            }]
        })
        setToken(response.data.token);
    }, [type, setToken, user, type, stream, room, robot, kind]);

    return <div>
        <h2>Client Subscriber</h2>
        <Form>
            <FormGroup tag="fieldset">
                <legend>Select subscription type</legend>
                <FormGroup check>
                    <Label check>
                        <Input checked={type === "all"} type="radio" name="radio1" onChange={() => setType("all")} />{' '}
                        All
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input checked={type === "user"} type="radio" name="radio1" onChange={() => setType("user")} />{' '}
                        User
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input checked={type === "room"} type="radio" name="radio1" onChange={() => setType("room")} />{' '}
                        Room
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input checked={type === "robot"} type="radio" name="radio1" onChange={() => setType("robot")} />{' '}
                        Robot
                    </Label>
                </FormGroup>
                <FormGroup check disabled>
                    <Label check>
                        <Input checked={type === "stream"} type="radio" name="radio1" onChange={() => setType("stream")} />{' '}
                        Stream
                    </Label>
                </FormGroup>
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">User id</Label>
                <Input disabled={type === "all"} value={user} onChange={(e) => setUser(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="User id" />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">Room id</Label>
                <Input disabled={type === "all" || type === "user"} value={room} onChange={(e) => setRoom(e.target.value)} type="text" name="text" id="exampleEmail" placeholder="Room id" />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">Robot id</Label>
                <Input value={robot} onChange={(e) => setRobot(e.target.value)} disabled={type === "room" || type === "user" || type === "all"} type="text" name="text" id="exampleEmail" placeholder="Robot id" />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">Stream id</Label>
                <Input value={stream} onChange={e => setStream(e.target.value)} disabled={type !== "stream"} type="text" name="text" id="exampleEmail" placeholder="Stream id" />
            </FormGroup>
        </Form>
        <Button disabled={
            (type === "user" && !user) ||
            (type === "room" && (!user || !room)) ||
            (type === "robot" && (!user || !robot) ||
                (type === "stream" && (!user || !robot || !stream)))
        } color={"success"} onClick={getToken}>Get Token</Button>
        <br/><br/>
        {token && <Input value={token} type="textarea" name="text" id="exampleText" />}
    </div>
}